import gql from 'graphql-tag'
import { FRAGMENT_SESSION } from './fragments'

export const RUN_SESSIONS = gql`query RUN_SESSIONS ($run_id: ID!, $user_id: ID!) {
    runSessions (run_id: $run_id, user_id: $user_id) {
        token
        module {
            id
            type
            texts {
                identifier
                text
                languagecode
            }
            created_at
        }
        tracking_status {
            score
            progress
            status
            data
            created_at,
            started_at,
            completed_at
        }
    }
}`

export const UPLOAD_FILE = gql`mutation UPLOAD_FILE ($file: UploadFileInput) {
    uploadFiles(data: [$file]) {
        id
        url
        name
        languagecode
        filename
        mime_type
        thumbnail
        slug
        size
    }
}`

export const UPLOAD_PACKAGE = gql`mutation UPLOAD_MODULE_PACKAGE ($file: UploadFileInput!) {
    uploadModulePackage (package: $file) {
        message
        path
        starter_file
        status
        type
    }
}`

export const LIST_TAGS = gql`query Tags (
    $filter: FilterInput!,
    $created_at: DateRange,
    $orderBy: [OrderByClause!],
    $first: Int!,
    $page: Int
) {
    tagCategories (filter: $filter, created_at : $created_at, orderBy: $orderBy, first: $first, page: $page) {
        data {
            id,
            identifier,
            tags {
                id,
                identifier,
                texts {
                    identifier
                    languagecode
                    text
                }
                category {
                    id
                    identifier
                    texts {
                        id
                        identifier
                        languagecode
                        text
                    }
                }
            }
        }
    }
}`

export const TAG_CATEGORIES_MINIMAL = gql`query TAG_CATEGORIES_MINIMAL (
    $filter: FilterInput!,
    $created_at: DateRange,
    $orderBy: [OrderByClause!],
    $first: Int!,
    $page: Int
) {
    tagCategories (filter: $filter, created_at : $created_at, orderBy: $orderBy, first: $first, page: $page) {
        data {
            id,
            identifier
        }
    }
}`

export const REQUEST_SUPPORT = gql`mutation requestSupport ($data: RequestSupportInput!) {
    requestSupport (data: $data)
}`

export const SET_ANON_USERNAME = gql`mutation SET_ANON_USERNAME ($data: SetAnonUsernameInput!) {
    setAnonUsername(data: $data) {
        status
        username
    }
}`

export const START_SESSION = gql`mutation START_SESSION ($data: StartSessionInput!) {
    startSession(data: $data) {
        ...SessionFull
    }
}
${FRAGMENT_SESSION}`

export const UPDATE_SESSION = gql`mutation UPDATE_SESSION ($data: UpdateSessionInput!) {
    updateSession(data: $data) {
        ...SessionFull
    }
}
${FRAGMENT_SESSION}`

export const REVOKE_SESSION = gql`mutation REVOKE_SESSION ($token: String!) {
    revokeSession(token: $token) {
        ...SessionFull
    }
}
${FRAGMENT_SESSION}`

export const ARCHIVE_SESSION_AND_RESTART = gql`mutation ARCHIVE_SESSION_AND_RESTART ($token: String!) {
    archiveSessionAndRestart(token: $token) {
        ...SessionFull
    }
}${FRAGMENT_SESSION}`

export const STORE_SESSION_TRACKING = gql`mutation STORE_SESSION_TRACKING ($data: StoreSessionTrackingInput!) {
    storeSessionTracking(data: $data) {
        ...SessionFull
    }
}
${FRAGMENT_SESSION}`

export const EXPORT_CONTENT = gql`mutation EXPORT_CONTENT ($data: ExportContentInput!) {
    exportContent(data: $data)
}`

export const IMPORT_CONTENT = gql`mutation IMPORT_CONTENT ($data: ImportContentInput!) {
    importContent(data: $data)
}`

export const STATUS_LIGHT = gql`query STATUS_LIGHT {
    status {
        status
        user {
            id
        }
    }
}`

export const STATUS = gql`query STATUS {
    status {
        name
        debug
        environment
        status
        version
        packages
    }
}`
