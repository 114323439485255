



















































import mixins from 'vue-typed-mixins'

// @ts-ignore
import MobileDetection from '../../mixins/utils/MobileDetection'
import ValidationRules from '../../mixins/utils/ValidationRules'
import { MUTATIONS } from '@simpl/auth/store/consts'
import { SET_ANON_USERNAME } from '../../graphql'
import { SetAnonUsernameInput } from '../../types/graphql'

export default mixins(MobileDetection, ValidationRules).extend({
  name: 'SetAnonUsernameDialog',

  components: {},

  model: {},

  props: {
    value: Boolean
  },

  data: () => ({
    username: '',
    errorMessage: '',
    checking: false
  }),

  computed: {
    show: {
      get (): boolean {
        return this.value
      },
      set (v: boolean) {
        this.$emit('input', v)
      }
    }
  },

  watch: {
    show (v) {
      if (!v) return
      this.username = this.$store.state.auth.anonUsername
      this.errorMessage = ''
    }
  },

  methods: {
    cancel () {
      this.show = false
    },
    async setUsername () {
      if (this.username === this.$store.state.auth.anonUsername) {
        this.show = false
        return
      }
      this.checking = true
      const data: SetAnonUsernameInput = {
        slug: this.$route.params.slug,
        username: this.username
      }
      if (this.$store.state.auth.anonUsername) {
        data.old_username = this.$store.state.auth.anonUsername
      }
      const result = await this.$apollo.mutate({
        mutation: SET_ANON_USERNAME,
        variables: {
          data: data
        }
      })
      if (result.data.setAnonUsername.status === 'error') {
        this.errorMessage = this.$t('validation.unique', { attribute: this.username }) as string
        this.username = result.data.setAnonUsername.username
      } else {
        this.$store.commit(`auth/${MUTATIONS.SET_ANON_USERNAME}`, this.username)
        this.show = false
      }
      this.checking = false
    }
  }
})
