















































































import Vue, { Component } from 'vue'
import { mapMutations, mapState } from 'vuex'
import DashboardTile from '../components/DashboardTile.vue'
import { getTextForUserLanguage } from '../utils'
import GuidedTour from '../components/GuidedTour.vue'
import { sleep } from '../utils/core'
import { AppModuleDashboard } from '../plugins/app-modules'
import { LEARNING_PATHS } from '@simpl/base-management/runs/graphql'
import { LearningPath, ParticipantDashboardLearningPath, Query, Status } from '../types/graphql'
import ParticipantLearningPaths from '@simpl/base-management/learning-paths/views/ParticipantLearningPaths.vue'
import { STATUS_LIGHT } from '../graphql'

const TILE_BREAKPOINTS = [{
  size: 520,
  col: 6
}, {
  size: 860,
  col: 4
}, {
  size: 1100,
  col: 3
}, {
  size: 1440,
  col: 2
}]

export default Vue.extend({
  name: 'Dashboard',

  components: {
    GuidedTour,
    DashboardTile,
    ParticipantLearningPaths
  },

  data () {
    return {
      loading: 0,
      loadingWidgets: 0,

      colSize: 2,

      guidedTour: {
        icon: 'mdi-brightness-1',
        steps: [{
          selector: '.dashboard',
          headline: this.$t('core.guidedTour.dashboard.welcome'),
          text: this.$t('core.guidedTour.dashboard.welcomeText'),
          position: 'center',
          width: this.$vuetify.breakpoint.xs ? '90vw' : 500
        }, {
          selector: '.dashboard-tile',
          text: this.$t('core.guidedTour.dashboard.tiles'),
          position: this.$vuetify.breakpoint.xs ? 'center' : 'right'
        }, {
          selector: '.link--root',
          text: this.$t('core.guidedTour.dashboard.homeButton'),
          position: this.$vuetify.breakpoint.xs ? 'bottom' : 'right'
        }, {
          selector: '.user-dropdown--activator',
          text: this.$t('core.guidedTour.dashboard.userProfileActivator'),
          position: 'left',
          width: this.$vuetify.breakpoint.xs ? 200 : undefined
        }, {
          pre: async () => {
            (this as any).forceUserDropdown(true)
            await sleep(200)
          },
          selector: '.user-dropdown',
          text: this.$t('core.guidedTour.dashboard.userProfile'),
          position: this.$vuetify.breakpoint.xs ? 'bottom' : 'left',
          post: async () => {
            (this as any).forceUserDropdown(false)
          }
        }, {
          selector: null,
          text: this.$t('core.guidedTour.dashboard.haveFun'),
          position: 'center',
          width: this.$vuetify.breakpoint.xs ? '90vw' : 500
        }]
      },

      learningPaths: null! as LearningPath[],
      status: null! as Status
    }
  },

  computed: {
    appModules (): (AppModuleDashboard & { key: string })[] {
      const preFiltered: (AppModuleDashboard & { key: string })[] = Object.keys(this.$appModules.all())
        .map(key => {
          const appModule = this.$appModules.all()[key]
          return appModule.dashboard
            ? {
              key,
              ...appModule.dashboard
            }
            : undefined
        })
        .filter(Boolean) as (AppModuleDashboard & { key: string })[]

      return preFiltered
        .filter(m =>
          !m.permission ||
          typeof m.permission === 'string'
            ? this.$permission.can(null, m.permission!)
            : m.permission()
        )
        .sort((a, b) => (a.order || 0) - (b.order || 0))
    },
    appModuleImages (): Record<string, string> {
      // TODO: ÄNDERUNG VON JH: wenn es keine domainTheme gibt?
      // return this.$domainTheme.appModules?.[this.$vuetify.theme.dark ? 'dark' : 'light'] || {}
      return this.$domainTheme?.appModules?.[this.$vuetify.theme.dark ? 'dark' : 'light'] || {}
    },
    widgets (): Component[] {
      return Object.values(this.$appModules.all())
        .map(m => m.widget)
        .filter(Boolean) as Component[]
    },
    domainName (): string {
      return getTextForUserLanguage(this.user.active_domain)
    },
    showLearningPaths (): boolean {
      return !!this.learningPaths?.length
    },
    ...mapState('auth', ['user'])
  },

  watch: {
    learningPaths (v, o) {
      if (o) return
      if (this.$store.state.auth.impersonator || !!v?.length) return

      setTimeout(async () => {
        this.setGuidedTour({
          id: 'dashboard',
          tours: [this.guidedTour]
        })
      }, 500)
    }
  },

  mounted () {
    if (this.$permission.can(null, 'run-update')) {
      this.setGuidedTour({
        id: 'dashboard',
        tours: [this.guidedTour]
      })
    }
  },

  beforeDestroy () {
    this.setGuidedTour()
  },

  apollo: {
    learningPaths: {
      query: LEARNING_PATHS,

      fetchPolicy: 'network-only',

      skip (): boolean {
        return !!this.$permission.can(null, 'run-update')
      },

      update (result: Query): ParticipantDashboardLearningPath[] {
        return result.participantDashboard.learning_paths
      },

      error (error: Error) {
        console.error(error)
      },

      loadingKey: 'loading'
    },
    status: {
      query: STATUS_LIGHT,

      fetchPolicy: 'no-cache',

      update (result: Query): Status {
        if (!result.status!.user) {
          this.$router.push('/logout')
        }
        return result.status!
      }
    }
  },

  methods: {
    ...mapMutations(['setGuidedTour', 'forceUserDropdown']),

    calculateColumns () {
      const w = window.innerWidth
      let cols = 12
      for (const size of TILE_BREAKPOINTS) {
        if (w > size.size) {
          cols = Math.min(cols, size.col)
        }
      }
      this.colSize = cols
    }
  }
})
