<template>
  <v-app>
    <template v-if="booted">
      <Headful :title="pageTitle" />
      <!-- <CookieConsent v-if="showCookieConsent && !$route.meta.noCookieConsent" /> -->
      <v-fade-transition>
        <component
          :is="currentAppBar"
          v-if="currentAppBar"
          :logged-in="loggedIn"
          @show-plans="showDemoPopup"
          @update-page-title="updatePageTitle"
        />
      </v-fade-transition>

      <v-main
        :key="$store.state._appId"
        class="fill-height"
        :class="[lightTheme ? 'light-sheet-background' : 'dark-sheet-background', { safari: iOSAndSafari }]"
      >
        <v-fade-transition mode="out-in">
          <router-view />
        </v-fade-transition>
        <router-view name="dialog" />
      </v-main>

      <NotificationCenter
        group="bottom"
        style="align-items: center;"
      >
        <template #default="{ key, notification }">
          <v-fade-transition appear>
            <v-alert
              :key="key"
              colored-border
              appear
              :border="notification.border || 'left'"
              :type="notification.type"
              :color="notification.color || 'primary'"
              :elevation="notification.elevation || 2"
              :transition="notification.transition || 'scale-transition'"
              :dismissible="notification.dismissible"
            >
              {{ notification.message }}
            </v-alert>
          </v-fade-transition>
        </template>
      </NotificationCenter>

      <ConfirmDialog
        transition="fade-transition"
        confirm-transition="scale-transition"
      >
        <template #default="{ title, message, color, buttons, confirm }">
          <v-alert
            elevation="2"
            class="v-alert--confirm pa-0"
            :class="{
              'mr-3': $vuetify.breakpoint.smAndUp,
              'mt-3': $vuetify.breakpoint.smAndUp,
              'mx-auto': $vuetify.breakpoint.xs
            }"
            :style="{ alignSelf: $vuetify.breakpoint.xs ? 'center' : undefined }"
            :min-width="$vuetify.breakpoint.smAndUp ? 400 : '90%'"
            :max-width="$vuetify.breakpoint.smAndUp ? 600 : '90%'"
            colored-border
            :color="color || 'primary'"
            border="left"
          >
            <v-card elevation="0">
              <v-card-title
                v-if="title"
              >
                {{ title }}
              </v-card-title>
              <v-card-text class="font-weight-bold">
                {{ message }}
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  v-for="(button, index) in buttons"
                  v-bind="normalizeButtonType(button.type)"
                  :key="index"
                  :color="button.color || 'primary'"
                  :class="{ 'tt-upper': button.uppercase }"
                  min-width="86"
                  @click="confirm(button.answer)"
                >
                  {{ button.text }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-alert>
        </template>
      </ConfirmDialog>

      <GuidedTour
        v-if="guidedTourId && !$store.state.auth.impersonator"
        :id="guidedTourId"
        :tours="guidedTours"
      />

      <OfflineDialog
        v-model="offline"
      />
      <PlansDialog
        v-model="$store.state.auth.showPlansPopup"
      />
      <DemoDialog
        v-model="$store.state.auth.showDemoPopup"
      />
    </template>

    <v-fade-transition>
      <v-overlay
        v-if="!booted"
        key="loader"
        opacity="1"
      >
        <div class="preloader-wrapper d-flex flex-column justify-center align-center">
          <img
            alt="Logo"
            :src="$domainImages.logo ? $domainImages.logo : require('@/assets/logo-large.png')"
            :style="{ width: $vuetify.breakpoint.smAndUp ? '300px' : '80%' }"
            class="mb-12"
          >
          <v-progress-circular
            indeterminate
            color="primary"
          />
        </div>
      </v-overlay>
    </v-fade-transition>
  </v-app>
</template>

<script>
  import Ping from 'ping.js'

  import { mapState } from 'vuex'

  import OfflineDialog from '@simpl/core/components/dialogs/OfflineDialog'
  import AppBar from './components/AppBar.vue'
  import PlansDialog from './components/dialogs/PlansDialog.vue'
  import DemoDialog from './components/dialogs/DemoDialog.vue'
  import { MUTATIONS } from '@simpl/auth/store/consts'
  import GuidedTour from './components/GuidedTour.vue'
  import { getTextForUserLanguage, iOS, safari } from './utils'
  import Headful from './components/Headful'
  import { isDev } from './utils/env'

  const ping = new Ping({
    favicon: 'favicon.png'
  })

  export default {
    name: 'App',

    components: {
      GuidedTour,
      PlansDialog,
      DemoDialog,
      AppBar,
      OfflineDialog,
      Headful
    },

    data () {
      return {
        offline: false,
        currentPageTitle: ''
      }
    },

    computed: {
      iOSAndSafari () {
        return iOS && safari
      },
      ...mapState({
        loggedIn: (state) => !!state.auth.token && (!state.auth.user.tfa_enabled || !!state.auth.twoFactorToken),
        booted: (state) => state._booted,
        guidedTourId: (state) => state._guidedTourId,
        guidedTours: (state) => state._guidedTours
      }),
      currentAppBar () {
        const possibleComponents = this.$route.matched
          .map((route) => route.meta?.appBarComponent)
          .filter(comp => typeof comp !== 'undefined')
          .reverse()

        return typeof possibleComponents[0] !== 'undefined' ? possibleComponents[0] : AppBar
      },
      pageTitle: {
        get () {
          let textFromDomain = getTextForUserLanguage(this.$store.state.auth.domain, 'app.title')
          if (textFromDomain === 'app.title') {
            textFromDomain = getTextForUserLanguage(this.$store.state.auth.domain?.parent, 'app.title')
          }
          if (isDev && textFromDomain === 'app.title') {
            textFromDomain = getTextForUserLanguage(this.$store.state.auth.user?.active_domain, 'app.title')
          }

          const title = [
            (textFromDomain !== 'app.title') ? textFromDomain : process.env.VUE_APP_APP_TITLE,
            this.currentPageTitle
          ].filter(Boolean).join(' - ')

          const el = document.createElement('span')
          el.innerHTML = title

          return el.innerText
        },
        set (v) {
          this.currentPageTitle = v
        }
      },
      lightTheme () {
          return !this.$vuetify?.theme?.isDark
      }
    },

    /* watch: {
      booted (v) {
        if (v) {
          window.setTimeout(() => {
            this.showCookieConsent = true
          }, 500)
        }
      }
    }, */

    mounted () {
      if (process.env.NODE_ENV === 'production') {
        setTimeout(this.checkConnection, 10000)
      }
    },

    methods: {
      normalizeButtonType (type) {
        return { [type || null]: true }
      },
      checkConnection () {
        ping.ping(`${window.location.origin}/`, (err) => {
          this.offline = !!err
          setTimeout(this.checkConnection, this.offline ? 5000 : 30000)
        })
      },
      showDemoPopup () {
        this.$store.commit(`auth/${MUTATIONS.SET_SHOW_DEMO_POPUP}`, true)
      },
      updatePageTitle (v) {
        this.pageTitle = v
      }
    }
  }

</script>

<style lang="sass" scoped>
  .preloader-wrapper
    img
      height: auto

  .v-app-bar
    @media #{map-get($display-breakpoints, 'xs-only')}
      padding: 0 20px 0 0

  .v-toolbar__content
    padding: 0

    .v-btn.link--root:not(.v-btn--text):not(.v-btn--outlined)
      margin-left: 0
      width: 70px
      height: 60px
      margin-right: 12px

  .btn-logo:before
    display: none

  .safari
    padding-bottom: 75px !important
  .dark-sheet-background
    background: $dark-sheet-background
  .light-sheet-background
    background: $light-sheet-background

  .v-main__wrap
      > *
        overflow-y: auto
        overflow-x: hidden
</style>
