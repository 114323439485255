<template>
  <v-dialog
    v-model="dialog"
    width="600"
    scrollable
    persistent
    :fullscreen="$vuetify.breakpoint.xs"
  >
    <template #activator="{ on }">
      <slot
        v-bind="{ on }"
      ></slot>
    </template>

    <v-card tile>
      <v-card-title class="align-stretch flex-column">
        <Headline
          color="primary"
          class="display-1"
        >
          {{ $t('offlineDialog.noConnection') }}
        </Headline>
        <div class="body-1">
          {{ $t('offlineDialog.noConnectionText') }}
        </div>
      </v-card-title>
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="tt-upper"
          color="primary"
          @click="reload()"
        >
          {{ $t('core.global.reload') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'OfflineDialog',

    model: {},

    props: {
      value: Boolean
    },

    computed: {
      dialog: {
        get () {
          return this.value
        },
        set (v) {
          this.$emit('input', v)
        }
      }
    },

    methods: {
      cancel () {
        this.dialog = false
      },
      reload () {
        window.location.reload()
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
