import Vue from 'vue'
import { isValidEmail, isValidNamespace, isValidUrl } from '../../utils/pattern-validation'

export default Vue.extend({
  data () {
    return {
      rules: {
        required: (value: any | any[]) => (!!value?.length || !!value) || this.$t('core.global.required'),
        requiredRole: (value: any) => !!value || this.$t('core.global.required'),
        email: (value: string) => { return isValidEmail(value?.toLowerCase()) || this.$t('simplAuth.error.invalidMail') },
        url: (value: string) => { return isValidUrl(value) || this.$t('module.error.invalidUrl') },
        phoneNumber: (value: string) => {
          const pattern = /^[+-]?\d+$/
          return (pattern.test(value) || !value) || this.$t('core.global.invalidPhoneNumber')
        },
        validNamespace: (value: string) => {
          return isValidNamespace(value) || this.$t('accessControl.error.invalidNamespace')
        },
        usernameMinLength: (v: string) => {
          return (!v?.length || v.length >= 6) || this.$t('simplAuth.auth.usernameMinLength')
        },
        usernameNoStartEndWhitespace: (v: string) => {
          return (!v?.length || (v[0] !== ' ' && v[v.length - 1] !== ' ')) || this.$t('simplAuth.auth.usernameNoStartEndWhitespace')
        },
        twoFactorCodeLength: (v: string) => {
          return (v?.length && v.length >= 6) || this.$t('simplAuth.auth.twoFactorCodeLength')
        }
      }
    }
  },

  methods: {
  }
})
