import Vue from 'vue'
import ConfirmDialog from './ConfirmDialog'
import confirm from './confirm'

import { VueConfirmOptions } from './types'

declare module 'vue/types/vue' {
  export interface Vue {
    readonly $confirm: <T extends any>(options: VueConfirmOptions) => T
  }
}

Vue.prototype.$confirm = confirm

Vue.component('ConfirmDialog', ConfirmDialog)
