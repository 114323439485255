import { ActionTree } from 'vuex'

import { apolloCache, apolloProvider } from '@simpl/core/plugins/apollo'
import * as Queries from '../graphql'
import StateAuth from './types'
import { ACTIONS, MUTATIONS } from './consts'
import { app, unregisterFns } from '@simpl/core/init-app'
import router, { resetRoutes } from '@simpl/core/plugins/router'

const actions = {
  [ACTIONS.LOGIN]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apolloProvider.defaultClient.mutate({
        mutation: Queries.LOGIN,
        variables: {
          data: payload
        }
      }).then((resp: any) => {
        commit(MUTATIONS.SET_AUTH_PAYLOAD, resp.data.login)
        resolve(resp)
      }).catch((error: Error) => {
        reject(error)
      })
    })
  },
  [ACTIONS.AUTO_LOGIN]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apolloProvider.defaultClient.mutate({
        mutation: Queries.AUTO_LOGIN,
        variables: {
          token: payload
        }
      }).then((resp: any) => {
        commit(MUTATIONS.SET_AUTH_PAYLOAD, resp.data.loginRedirect)
        resolve(resp)
      }).catch((error: Error) => {
        reject(error)
      })
    })
  },
  [ACTIONS.REFRESH_AUTH]: ({ commit, state }) => {
    return new Promise((resolve, reject) => {
      apolloProvider.defaultClient.mutate({
        mutation: Queries.REFRESH_TOKEN,
        variables: {
          data: {
            username: state.user?.email,
            refresh_token: state.refreshToken
          }
        }
      }).then((resp: any) => {
        commit(MUTATIONS.SET_AUTH_PAYLOAD, resp.data.refreshToken)
        resolve(resp)
      }).catch((error: Error) => {
        reject(error)
      })
    })
  },
  [ACTIONS.RESET_PASSWORD]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apolloProvider.defaultClient.mutate({
        mutation: Queries.UPDATE_FORGOTTEN_PASSWORD,
        variables: {
          data: payload
        }
      }).then((resp: any) => {
        commit(MUTATIONS.SET_AUTH_PAYLOAD, resp.data.updateForgottenPassword)
        resolve(resp)
      }).catch((error: Error) => {
        reject(error)
      })
    })
  },
  [ACTIONS.ACCEPT_INVITATION]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apolloProvider.defaultClient.mutate({
        mutation: Queries.ACCEPT_INVITATION,
        variables: {
          data: payload
        }
      }).then((resp: any) => {
        commit(MUTATIONS.SET_AUTH_PAYLOAD, resp.data.acceptInvitation)
        resolve(resp)
      }).catch((error: Error) => {
        reject(error)
      })
    })
  },
  [ACTIONS.UPDATE_ME]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apolloProvider.defaultClient.mutate({
        mutation: Queries.UPDATE_ME,
        variables: {
          data: payload
        }
      }).then((resp: any) => {
        commit(MUTATIONS.UPDATE_ME, resp.data.updateMe)
        resolve(resp)
      }).catch((error: Error) => {
        reject(error)
      })
    })
  },
  [ACTIONS.LOGOUT]: async ({ commit }) => {
    apolloProvider.defaultClient.mutate({
      mutation: Queries.LOGOUT
    }).then(() => {})

    app.$appModules.clear()
    app.$permission.reset()

    unregisterFns.forEach(fn => fn())
    unregisterFns.clear()

    resetRoutes()
    await apolloCache.reset()

    commit(MUTATIONS.LOGOUT)
  },
  [ACTIONS.LOGOUT_BY_AUTH_ERROR]: async ({ commit, dispatch }, payload) => {
    commit(MUTATIONS.SET_LAST_URL, payload.lastUrl)
    await dispatch(ACTIONS.LOGOUT)
  },
  [ACTIONS.IMPERSONATE]: ({ commit, state }, payload) => {
    commit(MUTATIONS.IMPERSONATE, payload.auth_payload)
    commit(MUTATIONS.SET_TWO_FACTOR_TOKEN, payload.two_factor_token)
  },
  [ACTIONS.REVOKE_IMPERSONATION]: ({ commit, state }, payload) => {
    commit(MUTATIONS.REVOKE_IMPERSONATION, state.impersonator)
  },
  [ACTIONS.PERFORM_TWO_FACTOR_CHALLENGE]: async ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      apolloProvider.defaultClient.mutate({
        mutation: Queries.TWO_FACTOR_CHALLENGE,
        variables: {
          data: payload
        }
      }).then((resp: any) => {
        commit(MUTATIONS.SET_TWO_FACTOR_TOKEN, resp.data.twoFactorChallenge.two_factor_token)
        resolve(resp)
      }).catch((error: Error) => {
        reject(error)
      })
    })
  },
  [ACTIONS.CHECK_DOMAIN_STATUS]: ({ commit, state }, payload) => {
    if (state.domain && state.token) {
      if (state.domain.payment_status === 'expired' || state.domain.payment_status === 'demo-expired') {
        commit(MUTATIONS.SET_SHOW_DEMO_POPUP, true)
      } else if (state.domain.is_demo) {
        if (!state.lastDemoPopup || (state.lastDemoPopup + (90 * 60 * 1000)) < Date.now()) {
          commit(MUTATIONS.SET_LAST_DEMO_POPUP, Date.now())
          commit(MUTATIONS.SET_SHOW_DEMO_POPUP, true)
        } else {
          commit(MUTATIONS.SET_SHOW_DEMO_POPUP, false)
        }
      }
    }
  }
} as ActionTree<StateAuth, any>

export default actions
