import Vue from 'vue'

const UNITS = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
const STEP = 1024

function format (value: number, power: number): string {
  return (value / Math.pow(STEP, power)).toFixed(2) + UNITS[power]
}

const fileSize = function (value: any) {
  value = parseFloat(value)
  let i: number
  for (i = 0; i < UNITS.length; i++) {
    if (value < Math.pow(STEP, i)) {
      if (UNITS[i - 1]) {
        return format(value, i - 1)
      }
      return value + UNITS[i]
    }
  }
  return format(value, i - 1)
}

Vue.filter('fileSize', fileSize)
