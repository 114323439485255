
















































































import Vue from 'vue'
import { ParticipantDashboardLearningPath, Query, RunDashboard } from '@simpl/core/types/graphql'
import { LEARNING_PATHS } from '../../runs/graphql'
import SelectedLearningPath from '../components/SelectedLearningPath.vue'
import LearningPathTile from '../components/LearningPathTile.vue'
import Achievements from '../components/Achievements.vue'
import ProgressOverview from '../components/ProgressOverview.vue'
import { getTextForUserLanguage } from '@simpl/core/utils'

export default Vue.extend({
  name: 'ParticipantLearningPaths',

  components: { SelectedLearningPath, LearningPathTile, Achievements, ProgressOverview },

  data () {
    return {
      learningPaths: null! as ParticipantDashboardLearningPath[],
      selected: null! as ParticipantDashboardLearningPath,
      loading: 0
    }
  },

  computed: {
    otherLearningPaths (): ParticipantDashboardLearningPath[] {
      if (!this.selected) return []

      return this.learningPaths.filter((lp: ParticipantDashboardLearningPath) =>
        lp.learning_path.id !== this.selected.learning_path.id)
    }
  },

  apollo: {
    learningPaths: {
      query: LEARNING_PATHS,

      fetchPolicy: 'cache-and-network',

      update (result: Query): ParticipantDashboardLearningPath[] {
        const learningPaths = result.participantDashboard.learning_paths

        if (!this.selected) {
          this.selected = learningPaths[0]
        }

        return learningPaths
      },

      error (error: Error) {
        console.error(error)
      },

      loadingKey: 'loading'
    }
  },

  methods: {
    getTextForUserLanguage,

    completedCoursesCount (learningPath: ParticipantDashboardLearningPath): number {
      let completedCourses = 0

      // eslint-disable-next-line
      learningPath.run_dashboards.forEach((rd: RunDashboard) => {
        if (rd.configuration!.user.status === 'completed') {
          completedCourses++
        }
      })

      return completedCourses
    },
    allCoursesCompleted (learningPath: ParticipantDashboardLearningPath): boolean {
      return !!this.completedCoursesCount(learningPath) && this.completedCoursesCount(learningPath) === learningPath.run_dashboards.length
    },
    refetch () {
      this.$apollo.queries.learningPaths.refetch()
    }
  }
})
