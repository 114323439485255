import Vue, { ComponentOptions } from 'vue'

import debounce from 'lodash.debounce'

/* PACKAGES */
import preRegisterAuth from '@simpl/auth'

/* FILTERS */
import './filters'

/* PLUGINS */
import router, { addRoutes, resetRoutes } from './plugins/router'
import store from './plugins/store'
import ui from './plugins/ui'
import i18n from './plugins/i18n'
import { apolloCache, apolloProvider } from './plugins/apollo'
import './plugins/axios'
import './plugins/permissions'
import './plugins/notification-center'
import './plugins/confirm'
import './plugins/app-modules'
import './plugins/config'
import './plugins/sortable'
import './plugins/breadcrumbs'
import './plugins/event-bus'
import './plugins/mask'

/* ROOT COMPONENTS */
import App from './App.vue'
import Headline from './components/Headline.vue'
import SearchFilterBar from './components/SearchFilterBar.vue'
import SimplText from './directives/simpl-text'
import LocaleDateFormat from './directives/locale-date-format'
import Error404 from '@simpl/core/views/Error404.vue'

import { setPermissions } from './plugins/permissions/utils'

import { browserLanguageShort, setFavicon } from './utils'
import { importLocalizations } from './locales'
import { MUTATIONS } from '@simpl/auth/store/consts'

export let app: Vue
export let appOptions: ComponentOptions<any>
export const unregisterFns: Set<() => void> = new Set<() => void>()
let localeWatcher: () => void

export default async function initApp () {
  await import('./ui-hacks')

  await store.dispatch('initStore')

  applyDomainStyle()

  Vue.component('Headline', Headline)
  Vue.component('SearchFilterBar', SearchFilterBar)

  Vue.directive('simpl-text', SimplText)
  Vue.directive('locale-date-format', LocaleDateFormat)

  await preRegisterAuth(router, store)

  appOptions = {
    router,
    store,
    vuetify: ui,
    apolloProvider,
    i18n
  }

  store.commit(`auth/${MUTATIONS.SET_DOMAIN}`, window.DOMAIN)

  return (app = new Vue({
    ...appOptions,
    ...App,
    created: appCreated
  }))
}

export async function appCreated (actAsReload?: boolean) {
  if (actAsReload) {
    store.state._booted = false

    app.$appModules.clear()
    app.$permission.reset()

    unregisterFns.forEach(fn => fn())
    unregisterFns.clear()

    await apolloCache.reset()
  }

  const favIcon = window.DOMAIN_THEME?.images?.[ui.framework.theme.dark ? 'dark' : 'light']?.favicon ||
    `${process.env.BASE_URL}${process.env.VUE_APP_FAVICON}`
  setFavicon(favIcon)

  setPermissions()

  await Vue.nextTick(async () => {
    if (actAsReload) {
      store.state._appId = `SimpL_${+(new Date())}`
    }

    await importLocalizations(app)
    watchLocale()
    resetRoutes()

    const eagerPackages: string[] = JSON.parse(process.env.VUE_APP_EAGER_PACKAGES || '[]')
    const loader = [
      ...eagerPackages.map(registerEagerPackage),
      ...store.getters['auth/features'].filter((f: string) => f !== 'core').map(registerAppModule)
    ]
    await Promise.all(loader)

    store.commit('forcePersist')

    addRoutes([{
      path: '*',
      redirect: '/404',
      component: {}
    }, {
      path: '/404',
      component: Error404
    }])

    if (store.state.auth.user &&
        (!!app.$domainTheme?.appModules?.dark && !!app.$domainTheme?.appModules?.light) &&
        store.getters['auth/settings']('global').darkTheme !== undefined
    ) {
      app.$vuetify.theme.dark = store.getters['auth/settings']('global').darkTheme
    }

    window.setTimeout(() => {
      store.state._booted = true
    }, 50)
  })
}

async function registerEagerPackage (name: string) {
  let register: Function = () => {}

  try {
    const module = await import(
      /* webpackChunkName: "[request]" */
      `@simpl/${name}/eager.ts`
    )
    register = module.__esModule ? module.default : module
  } catch (e) {
    if (e.message.indexOf('Cannot find module') < 0) {
      console.error(e)
    }
  }

  const unregister = await register(Vue, app)
  if (typeof unregister === 'function') {
    unregisterFns.add(unregister)
  }
}

async function registerAppModule (name: string) {
  let register: Function = () => {}

  try {
    const module = await import(
      /* webpackChunkName: "[request]" */
      `@simpl/${name}/index.ts`
    )
    register = module.__esModule ? module.default : module
  } catch (e) {
    if (e.message.indexOf('Cannot find module') < 0) {
      console.error(e)
    }
  }

  const unregister = await register(Vue, app)
  if (typeof unregister === 'function') {
    unregisterFns.add(unregister)
  }
}

function applyDomainStyle (): void {
  if (window.DOMAIN_THEME?.css) {
    const styleEl = document.createElement('style')
    ;(styleEl as any).type = 'text/css'
    styleEl.appendChild(document.createTextNode(window.DOMAIN_THEME.css))
    document.head.appendChild(styleEl)
  }

  if (window.DOMAIN_THEME?.namespace) {
    document.body.classList.remove('simpl')
    document.body.classList.add(window.DOMAIN_THEME.namespace)
  }
}

function watchLocale () {
  if (localeWatcher) localeWatcher()

  localeWatcher = store.watch(state => state.auth.user?.languagecode, (value: any, oldValue: any) => {
    let newLocale: string = value

    if (i18n.availableLocales.includes(value!)) {
      newLocale = value!
    } else if (i18n.availableLocales.includes(browserLanguageShort)) {
      newLocale = browserLanguageShort
    } else {
      newLocale = process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en'
    }

    if (value) {
      i18n.locale = newLocale

      if (oldValue && store.state._booted) {
        appCreated(true).then(() => {})
      }
    }
  }, { immediate: true })
}
