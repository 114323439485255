var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":"","min-width":"250","max-width":"350","content-class":"user-dropdown","close-on-click":_vm.forced ? false : undefined,"close-on-content-click":_vm.forced ? false : undefined},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"pa-0 pl-2 user-dropdown--activator",style:({ marginLeft: _vm.$vuetify.breakpoint.xs ? '12px' : '24px' }),attrs:{"text":""}},on),[_c('v-badge',{attrs:{"value":!!_vm.$store.state.auth.impersonator,"color":"error","overlap":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" mdi-account-switch ")])]},proxy:true}],null,true)},[_c('v-avatar',{attrs:{"color":_vm.$vuetify.theme.dark ? 'white' : 'primary',"size":"32"}},[_c('span',{class:_vm.$vuetify.theme.dark ? 'black--text' : 'white--text'},[_vm._v(_vm._s(_vm.$t('core.global.fullname', [_vm.user.firstname.substr(0, 1), _vm.user.lastname.substr(0, 1)]).replace(/\s/g, '')))])])],1),_c('v-icon',[_vm._v(" mdi-menu-down ")])],1)]}}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-list',{attrs:{"dense":"","tile":""}},[_c('v-list-item',{attrs:{"two-line":"","inactive":true}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('core.global.fullname', [_vm.user.firstname, _vm.user.lastname]))+" ("+_vm._s(_vm.userRoleNames.length > 22 ? _vm.userRoleNames.substr(0, 18) + '...' : _vm.userRoleNames)+")")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.user.email))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.userDomainName))])],1)],1),(_vm.$store.state.auth.user.is_super)?[_c('v-divider',{staticClass:"my-2"}),_c('ContentDialog',{attrs:{"headline":_vm.$t('core.global.status'),"content":_vm.systemStatusContent,"loading":_vm.loadingSystemStatus,"fullscreen":_vm.$vuetify.breakpoint.smAndDown},on:{"open":_vm.loadSystemStatus},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-list-status")])],1),_c('v-list-item-title',[_vm._v("System Status")])],1)]}}],null,false,4048194704)})]:_vm._e(),_c('v-divider',{staticClass:"my-2"}),_c('v-list-item',{attrs:{"disabled":!!_vm.$store.state.auth.impersonator},on:{"click":_vm.showUserSettings}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-cogs")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('core.global.settings')))])],1),_c('v-divider',{staticClass:"my-2"}),_c('ContentDialog',{attrs:{"headline":_vm.$t('core.global.privacy'),"content":_vm.privacyContent,"fullscreen":_vm.$vuetify.breakpoint.smAndDown},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-shield-check")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('core.global.privacy')))])],1)]}}])}),(!_vm.hideLegalLink)?_c('LegalDisclosureDialog',{attrs:{"headline":_vm.$t('core.global.legal'),"fullscreen":_vm.$vuetify.breakpoint.smAndDown,"is-pdf":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-shield-check")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('core.global.legal')))])],1)]}}],null,false,506070529)}):_vm._e(),_c('ContentDialog',{attrs:{"headline":_vm.$t('core.global.imprint'),"content":_vm.imprintContent,"fullscreen":_vm.$vuetify.breakpoint.smAndDown},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-information")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('core.global.imprint')))])],1)]}}])}),(!_vm.$store.state.auth.impersonator)?_c('v-list-item',{attrs:{"to":'/logout'}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-power")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('simplAuth.auth.logout')))])],1):_vm._e(),(_vm.$store.state.auth.impersonator)?_c('v-list-item',{on:{"click":_vm.revokeImpersonation}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-switch")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('simplAuth.auth.revokeImpersonation')))])],1):_vm._e()],2),_c('UserSettingsDialog',{model:{value:(_vm.userSettingsDialog),callback:function ($$v) {_vm.userSettingsDialog=$$v},expression:"userSettingsDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }