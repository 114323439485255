import Pusher, { AuthorizerCallback } from 'pusher-js'
import type Channel from 'pusher-js/types/src/core/channels/channel'
import type PresenceChannel from 'pusher-js/types/src/core/channels/presence_channel'
import { axios } from '../axios'
import store from '../store'
import Vue from 'vue'

const pusher = new Pusher(process.env.VUE_APP_GRAPHQL_KEY!, {
  cluster: 'mt1',
  httpHost: process.env.VUE_APP_GRAPHQL_WS_HTTP,
  authEndpoint: process.env.VUE_APP_PUSHER_AUTH_ENDPOINT,
  authorizer: _authorize,
  wsHost: process.env.VUE_APP_GRAPHQL_WS_HTTP,
  wssPort: 443,
  forceTLS: true,
  enableStats: false,
  enabledTransports: ['ws']
})

function _authorize (channel: Channel): any {
  return {
    authorize (socketId: string, callback: AuthorizerCallback) {
      // const channelSuffix = channel.name.startsWith('private-') ? '/private' : ''
      const authData: any = {
        socket_id: socketId,
        channel_name: channel.name
      }

      if (store.state.auth.anonUsername) {
        authData.username = store.state.auth.anonUsername
      }

      axios.post(process.env.VUE_APP_PUSHER_AUTH_ENDPOINT!, authData).then((res) => {
        callback(null, res.data)
      })
    }
  }
}

Vue.use(() => {
  Vue.prototype.$pusher = pusher
})

declare module 'vue/types/vue' {
  interface Vue {
    $pusher: Pusher
  }
}

export default pusher
export type {
  Channel,
  PresenceChannel
}
