

























import Vue from 'vue'
import { Run } from '@simpl/core/types/graphql'
import { getTextForTemporaryUserLanguage } from '@simpl/core/utils'

export default Vue.extend({
  name: 'RunDescription',

  props: {
    run: Object as () => Run,
    darkBackground: Boolean
  },

  data () {
    return {
      descriptionOpened: false,
      showReadMore: false
    }
  },

  computed: {
    description (): string {
      const text = getTextForTemporaryUserLanguage(this.run, 'description') as string
      return text !== 'description' ? text : ''
    }
  },

  mounted () {
    this.showReadMore = this.isTextOverflowing(this.$refs.description as Element)
  },

  methods: {
    isTextOverflowing (el: Element): boolean {
      return el?.clientHeight < el?.scrollHeight
    }
  }
})
