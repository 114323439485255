




















































import Vue from 'vue'
import { LearningPath } from '@simpl/core/types/graphql'

export type MandatoryLevel = {
  value: string,
  icon: string,
  color: string
}

export default Vue.extend({
  name: 'LearningPathTile',

  props: {
    learningPath: Object as () => LearningPath,
    completed: Boolean
  },

  data () {
    return {
      mandatoryLevels: [{
        value: '0-compulsive',
        icon: 'mdi-alert-octagon',
        color: 'error'
      }, {
        value: '1-recommended',
        icon: 'mdi-tag-heart-outline',
        color: 'warning'
      }, {
        value: '2-optional',
        icon: 'mdi-puzzle',
        color: 'success'
      }] as MandatoryLevel[]
    }
  },

  computed: {
    mandatoryLevel (): MandatoryLevel {
      return this.mandatoryLevels.find((ml) => ml.value === this.learningPath.mandatory_level)!
    }
  }
})
