import gql from 'graphql-tag'

const FRAGMENT_AUTH_USER = gql`fragment AUTH_USER on User {
    id
    firstname
    lastname
    username
    email
    languagecode
    department
    is_super
    settings
    tfa_enabled
    active_domain {
        id
        identifier
        texts {
            identifier
            languagecode
            text
        }
        properties
    },
    domains {
        id
        identifier
        texts {
            identifier
            languagecode
            text
        }
        properties
    }
    roles {
        id
        identifier
        permissions {
            id
            type
            ability
            feature {
                id
                identifier
                active
            }
        }
        texts {
            identifier
            languagecode
            text
        }
    }
    tags {
        id
        identifier
        texts {
            identifier
            languagecode
            text
        }
        category {
            id
            identifier
        }
    }
}`

export const CREATE_ACCOUNT = gql`mutation createAccount ($data: NewAccountInput!) {
    createAccount(data: $data) {
        email
        active_domain {
            properties
        }
    }
}`

export const LOGIN = gql`mutation login ($data: LoginInput!) {
    login(data: $data) {
        access_token,
        refresh_token,
        expires_in,
        user {
            ...AUTH_USER
        }
    }
}
${FRAGMENT_AUTH_USER}`

export const LOGOUT = gql`mutation logout {
    logout {
        status
    }
}`

export const REFRESH_TOKEN = gql`mutation REFRESH_TOKEN ($data: RefreshTokenInput!) {
    refreshToken (data: $data) {
      access_token
      refresh_token
      expires_in
      user {
          ...AUTH_USER
      }
  }
}
${FRAGMENT_AUTH_USER}`

export const IMPERSONATE = gql`mutation impersonate ($data: ImpersonateInput!) {
    impersonate(data: $data) {
        auth_payload {
            access_token,
            refresh_token,
            expires_in,
            user {
                ...AUTH_USER
            }
        }
        two_factor_token
    }
}
${FRAGMENT_AUTH_USER}`

export const FORGOT_PASSWORD = gql`mutation FORGOT_PASSWORD ($data: ForgotPasswordInput!) {
  forgotPassword (data: $data) {
    status
    message
    access_token
      user {
          ...AUTH_USER
      }
  }
}
${FRAGMENT_AUTH_USER}`

export const UPDATE_FORGOTTEN_PASSWORD = gql`mutation UPDATE_FORGOTTEN_PASSWORD ($data: NewPasswordWithCodeInput!) {
  updateForgottenPassword (data: $data) {
    access_token
    refresh_token
    expires_in
      user {
          ...AUTH_USER
      }
  }
}
${FRAGMENT_AUTH_USER}`

export const ACCEPT_INVITATION = gql`mutation ACCEPT_INVITATION ($data: AcceptInvitationWithPasswordAndCodeInput!) {
  acceptInvitation (data: $data) {
    access_token
    refresh_token
    expires_in
    token_type
    user {
      ...AUTH_USER
    }
  }
}
${FRAGMENT_AUTH_USER}`

export const REJECT_INVITATION = gql`mutation REJECT_INVITATION ($data: RejectInvitationInput!) {
  rejectInvitation (data: $data) 
}`

export const VERIFY_USER = gql`mutation VERIFY_USER ($id: ID!) {
    verifyUser (id: $id) {
        email
    }
}`

export const VERIFY_PENDING_USER = gql`mutation VERIFY_PENDING_USER ($id: String!) {
    verifyPendingUser (id: $id) {
        email
    }
}`

export const AUTO_LOGIN = gql`mutation AUTO_LOGIN ($token: String!) {
    loginRedirect (token: $token) {
        access_token,
        refresh_token,
        expires_in,
        user {
            ...AUTH_USER
        }
    }
}
${FRAGMENT_AUTH_USER}`

export const UPDATE_ME = gql`mutation UPDATE_ME ($data: UpdateMeInput!) {
  updateMe (data: $data) {
      ...AUTH_USER
  }
}
${FRAGMENT_AUTH_USER}`

export const OPT_OUT = gql`mutation OPT_OUT ($data: OptOutInput!) {
  optOut (data: $data) 
}`

export const REGISTER_PARTICIPANT = gql`mutation REGISTER_PARTICIPANT($data: ParticipantRegistrationInput!){
    registerParticipant(data: $data) {
        run_evaluation {
            progress
            score
            status
        }
    }
}`

export const REGISTER_USER = gql`mutation REGISTER_USER ($data: RegisterUserInput!) {
  registerUser (data: $data) {
      id
  }
}`

export const TWO_FACTOR_INITIALIZE = gql`query TWO_FACTOR_INITIALIZE {
  twoFactorInitialize {
      secret
      url
  } 
}`

export const TWO_FACTOR_ACTIVATE = gql`mutation TWO_FACTOR_ACTIVATE($data: TwoFactorChallengeInput!){
    twoFactorActivate(data: $data) {
        two_factor_token
        user {
            ...AUTH_USER
        }
    }
}
${FRAGMENT_AUTH_USER}`

export const TWO_FACTOR_CHALLENGE = gql`mutation TWO_FACTOR_CHALLENGE($data: TwoFactorChallengeInput!){
    twoFactorChallenge(data: $data) {
        two_factor_token
    }
}`

export const TWO_FACTOR_DEACTIVATE = gql`mutation TWO_FACTOR_DEACTIVATE{
    twoFactorDeactivate {
        ...AUTH_USER
    }
}
${FRAGMENT_AUTH_USER}`
