var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',[_c('div',{staticClass:"d-flex fill-height flex-column"},[(_vm.$domainImages.welcome)?_c('v-img',{staticClass:"flex-grow-0 flex-shrink-0",attrs:{"width":"100%","aspect-ratio":1250 / 542,"src":_vm.$domainImages.welcome}}):_vm._e(),_c('div',{staticClass:"d-flex flex-column grow",class:{
        'px-6': _vm.$vuetify.breakpoint.smAndDown,
        'py-8': _vm.$vuetify.breakpoint.smAndDown,
        'mx-auto': _vm.$vuetify.breakpoint.smAndDown
      },staticStyle:{"width":"100%"},style:({
        'max-width': _vm.$vuetify.breakpoint.smAndDown ? '500px' : undefined,
        'padding-left': _vm.$vuetify.breakpoint.mdAndUp ? '5vw' : undefined,
        'justify-content': _vm.$domainImages.welcome ? undefined : 'center'
      })},[_c('Headline',{staticClass:"display-2 pt-12",attrs:{"decorator-width":64}},[_vm._v(" "+_vm._s(_vm.headline)+" ")]),_c('div',{style:({ width: _vm.$vuetify.breakpoint.smAndDown ? undefined : '80%' }),domProps:{"innerHTML":_vm._s(_vm.text)}})],1),_c('div',{style:({ 'padding-left': _vm.$vuetify.breakpoint.mdAndUp ? 'calc(5vw - 32px)' : undefined })},[_vm._t("footer")],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }