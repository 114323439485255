









































import Vue from 'vue'
import ContactDialog from '@simpl/core/components/dialogs/ContactDialog.vue'
import { MUTATIONS } from '@simpl/core/plugins/store/consts'

export default Vue.extend({
  components: {
    ContactDialog
  },

  data: () => ({
    dialog: ''
  }),
  computed: {
    hideLegalLink () {
      return this.$store.state.auth.domain?.properties?.hideLegalLink
    }
  },
  methods: {
    openPopup (name: string) {
      if (this.$route.name === 'demo') {
        this.$router.push({ name: 'demo.' + name })
      } else {
        this.$router.push({ name: 'login.' + name })
      }
    },
    resetCookieConsent () {
      this.$store.commit(MUTATIONS.SET_COOKIE_CONSENT_ACCEPTED, false)
    }
  }
})
