import Vue, { VNode } from 'vue'
import { VueConfirmOptions } from './types'
import { confirmInstances } from './confirm'

type RGB = { r: number, g: number, b: number }

export default Vue.extend({
  name: 'ConfirmDialog',

  props: {
    name: {
      type: String,
      default: 'default'
    },
    backdropColor: {
      type: String,
      default: '#000000'
    },
    backdropOpacity: {
      type: Number,
      default: 0.6
    },
    backdropStyle: {
      type: Object,
      default: () => ({})
    },
    zIndex: {
      type: [Number, String],
      default: 501
    },
    transition: String,
    confirmTransition: String
  },

  data () {
    return {
      visible: false,
      data: {} as VueConfirmOptions
    }
  },

  computed: {
    backdropRgb (): RGB {
      return hexToRgb(this.backdropColor)
    }
  },

  created () {
    if (confirmInstances[this.name]) {
      console.warn('[HawkeyeConfirm] Found existing instance for name "' + name + '". Old instance will be overwritten')
    }
    confirmInstances[this.name] = this
  },

  beforeDestroy () {
    delete confirmInstances[this.name]
  },

  methods: {
    show (resolve: void) {
      this.visible = true;
      (this as any).resolve = resolve
    },

    confirm (value: any) {
      (this as any).resolve(value)
      this.visible = false
    },

    _createBackdropTransition (backdrop: VNode | null) {
      if (!this.transition) return backdrop

      return this.$createElement('transition', {
        props: {
          name: this.transition,
          appear: true
        }
      }, [backdrop])
    },
    _createBackdrop () {
      const { r, g, b } = this.backdropRgb
      let backdrop = null

      if (this.visible) {
        backdrop = this.$createElement('div', {
          style: Object.assign({
            position: 'fixed',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            zIndex: this.zIndex,
            backgroundColor: `rgba(${r}, ${g}, ${b}, ${this.backdropOpacity})`,
            display: 'flex',
            alignItems: this.data.y === 'top' ? 'flex-start' : this.data.y === 'bottom' ? 'flex-end' : 'center',
            justifyContent: this.data.x === 'left' ? 'flex-start' : this.data.x === 'right' ? 'flex-end' : 'center'
          }, this.backdropStyle)
        }, [this._createContent()])
      }

      return this._createBackdropTransition(backdrop)
    },

    _createContentTransition (content?: VNode[] | null) {
      if (!this.confirmTransition) return content

      return this.$createElement('transition', {
        props: {
          name: this.confirmTransition,
          appear: true
        }
      }, [content])
    },
    _createContent () {
      let content = null

      if (this.visible) {
        content = (this.$scopedSlots.default || (() => null))({
          ...this.data,
          confirm: this.confirm
        })
      }

      return this._createContentTransition(content)
    }
  },

  render () {
    // FIXME: WTF?
    return (this as any)._createBackdrop()
  }
})

function hexToRgb (hex: string): RGB {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? { r: parseInt(result[1], 16), g: parseInt(result[2], 16), b: parseInt(result[3], 16) }
    : { r: 0, g: 0, b: 0 }
}
