<template>
  <component
    :is="tag"
    class="headline-component"
    :class="classesAndStyles.class"
    :style="classesAndStyles.style"
  >
    <slot></slot>
    <div
      class="decorator"
      :class="decoratorClasses"
      :style="{ width: `${decoratorWidth}px` }"
    ></div>
  </component>
</template>

<script>
  function isCssColor (color) {
    return !!color && !!color.match(/^(#|var\(--|(rgb|hsl)a?\()/)
  }

  export default {
    name: 'CoreHeadline',

    inject: {
      theme: {
        default: {
          isDark: false
        }
      }
    },

    props: {
      tag: {
        type: String,
        default: 'div'
      },
      color: {
        type: String
      },
      decoratorClasses: {
        type: String,
        default: 'mt-2 mb-6'
      },
      decoratorWidth: {
        type: Number,
        default: 36
      }
    },

    computed: {
      appIsDark () {
        return this.$vuetify.theme.dark || false
      },
      isInDark () {
        return this.theme && this.theme.isDark
      },
      computedColor () {
        if (this.color) return this.color

        if (this.isInDark) return 'white'

        return 'black'
      },
      classesAndStyles () {
        const data = {
          class: {},
          style: {}
        }
        if (isCssColor(this.computedColor)) {
          data.style = {
            color: `${this.computedColor}`,
            'caret-color': `${this.computedColor}`
          }
        } else if (this.computedColor) {
          const [colorName, colorModifier] = this.computedColor.toString().trim().split(' ', 2)
          data.class = {
            [colorName + '--text']: true
          }
          if (colorModifier) {
            data.class['text--' + colorModifier] = true
          }
        }
        return data
      }
    }
  }
</script>

<style lang="scss">
  .headline-component {
    position: relative;

    .decorator {
      height: 2px;
      background-color: currentColor;
    }
  }
</style>
