












































































































import mixins from 'vue-typed-mixins'
import { getKeyvisual } from '../../utils/keyvisual'
import { getTextForUserLanguage } from '@simpl/core/utils'
import { File as GraphQLFile, LearningPath, RunDashboard } from '@simpl/core/types/graphql'
import LearningPathStepper from './LearningPathStepper.vue'
import RunTypes from '../../runs/mixins/RunTypes'

export type MandatoryLevel = {
  value: string,
  icon: string,
  color: string
}

export default mixins(RunTypes).extend({
  name: 'SelectedLearningPath',

  components: {
    LearningPathStepper
  },

  props: {
    learningPathData: Object
  },

  data () {
    return {
      mandatoryLevels: [{
        value: '0-compulsive',
        icon: 'mdi-alert-octagon',
        color: 'error'
      }, {
        value: '1-recommended',
        icon: 'mdi-tag-heart-outline',
        color: 'warning'
      }, {
        value: '2-optional',
        icon: 'mdi-puzzle',
        color: 'success'
      }] as MandatoryLevel[],

      courseDescriptionOpened: false
    }
  },

  computed: {
    learningPath (): LearningPath {
      return this.learningPathData?.learning_path
    },
    runDashboards (): RunDashboard[] {
      return this.learningPathData?.run_dashboards
    },
    isConsecutive (): boolean {
      return this.learningPath.properties?.consecutive
    },
    completedCoursesCount (): number {
      let completedCourses = 0

      // eslint-disable-next-line
      this.learningPathData.run_dashboards.forEach((rd: RunDashboard) => {
        if (rd.configuration!.user.status === 'completed') {
          completedCourses++
        }
      })

      return completedCourses
    },
    allCoursesCompleted (): boolean {
      return !!this.completedCoursesCount && this.completedCoursesCount === this.learningPathData.run_dashboards.length
    },
    keyvisual (): GraphQLFile | undefined {
      return getKeyvisual(this.learningPath)
    },
    mandatoryLevel (): MandatoryLevel {
      return this.mandatoryLevels.find((ml) => ml.value === this.learningPath.mandatory_level)!
    }
  },

  methods: {
    getTextForUserLanguage
  }
})
