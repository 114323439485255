import { File as GraphQLFile } from '@simpl/core/types/graphql'
import { browserLanguageShort } from '@simpl/core/utils'
import store from '@simpl/core/plugins/store'

export function getKeyvisual (
  item: any,
  languagecode?: string
): GraphQLFile {
  const keyvisual = item.files?.find((file: GraphQLFile) => file?.languagecode === item.languagecode) ||
    item.files?.find((file: GraphQLFile) => isGeneric(file))

  if (languagecode) {
    return item.files?.find((file: GraphQLFile) => file?.languagecode === languagecode &&
      !isGeneric(file)) || keyvisual
  }

  return keyvisual
}

export function getKeyvisualForUserLanguage (
  item: any
): GraphQLFile {
  const language = store.state.auth?.user?.languagecode || browserLanguageShort || 'en'

  return item.files?.find((file: GraphQLFile) => file?.languagecode === language) ||
    item.files?.find((file: GraphQLFile) => isGeneric(file))
}

export function getKeyvisualForTemporaryUserLanguage (
  item: any
): GraphQLFile {
  const language = store.state.auth?.temporaryLanguage

  return item.files?.find((file: GraphQLFile) => file?.languagecode === language) || getKeyvisualForUserLanguage(item)
}

export function hasCustomKeyvisual (
  item: any
): boolean {
  return item.files!.find((file: GraphQLFile) => file?.languagecode === item.languagecode &&
    !isGeneric(file))
}

export function hasCustomKeyvisuals (
  item: any,
  languages: Record<string, any>[]
): boolean {
  for (let i = 0; i < languages.length; i++) {
    if (item.files!.find((file: GraphQLFile) => file?.languagecode === languages[i].identifier &&
      !isGeneric(file))) return true
  }

  return false
}

export function updateKeyvisual (
  item: any,
  keyvisual: GraphQLFile,
  languagecode?: string
): void {
  const lang = languagecode || item.languagecode

  const languageVersionIndex = item.files!.findIndex((file: GraphQLFile) => file?.languagecode === lang &&
    !isGeneric(file))

  const genericIndex = item.files!.findIndex((file: GraphQLFile) => isGeneric(file))

  const index = languageVersionIndex > -1 ? languageVersionIndex : genericIndex

  if (index > -1) {
    item.files!.splice(index, 1, keyvisual)
  } else {
    item.files!.push(keyvisual)
  }
}

export function removeLanguageKeyvisual (
  item: any,
  languagecode: string
): void {
  const index = item.files!.findIndex((file: GraphQLFile) => file?.languagecode === languagecode &&
    !isGeneric(file))

  item.files!.splice(index, 1)
}

export function isGeneric (
  keyvisual: GraphQLFile
): boolean {
  return keyvisual?.path?.includes('/generic_keyvisuals/') || false
}

export function isFallback (
  item: any,
  languagecode: string
): boolean {
  return item.languagecode !== languagecode && getKeyvisual(item).id === getKeyvisual(item, languagecode).id
}
