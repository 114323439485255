import gql from 'graphql-tag'
import {
  FRAGMENT_SESSION,
  FRAGMENT_USER_WITHOUT_PERMISSIONS
} from '@simpl/core/graphql/fragments'

export const FRAGMENT_RUN_LIST = gql`fragment RunList on Run {
    id
    identifier
    version
    type
    languagecode
    checkin_type
    starts_at
    ends_at
    created_at
    updated_at
    is_public
    shareable
    periodic
    active
    users {
        id
        firstname
        lastname
        email
    }
    texts {
        identifier
        languagecode
        text
    }
    files {
        id
        name
        languagecode
        url
        path
    }
    tags {
        category {
            id
            identifier
        }
        id
        identifier
        texts  {
            identifier
            languagecode
            text
        }
    }
    waves {
        starts_at
    }
}`

export const LIST_RUNS = gql`query listRuns (
    $filter: FilterInput!,
    $first: Int!,
    $page: Int,
    $orderBy: [OrderByClause!]
) {
    runs (filter: $filter, first: $first, page: $page, orderBy: $orderBy) {
        data {
            ...RunList
        },
        paginatorInfo {
            count
            currentPage
            hasMorePages
            lastPage
            perPage
            total
        }
    }
}${FRAGMENT_RUN_LIST}`

export const LIST_RUNS_SHARED_WITH = gql`query listRunsSharedWith (
    $filter: FilterInput!,
    $first: Int!,
    $page: Int,
    $orderBy: [OrderByClause!]
) {
    runs (filter: $filter, first: $first, page: $page, orderBy: $orderBy) {
        data {
            id
            identifier
            texts {
                identifier
                languagecode
                text
            }
            shared_with {
                id
            }
        },
        paginatorInfo {
            count
            currentPage
            hasMorePages
            lastPage
            perPage
            total
        }
    }
}`

export const LIST_EVENTS = gql`query listEvents (
    $filter: FilterInput!,
    $first: Int!,
    $page: Int,
    $orderBy: [OrderByClause!]
) {
    runs (filter: $filter, first: $first, page: $page, orderBy: $orderBy) {
        data {
            ...RunList
        },
        paginatorInfo {
            count
            currentPage
            hasMorePages
            lastPage
            perPage
            total
        }
    }
}${FRAGMENT_RUN_LIST}`

export const LIST_COURSES = gql`query listCourses (
    $filter: FilterInput!,
    $first: Int!,
    $page: Int,
    $orderBy: [OrderByClause!]
) {
    courses (filter: $filter, first: $first, page: $page, orderBy: $orderBy) {
        data {
            ...RunList
        },
        paginatorInfo {
            count
            currentPage
            hasMorePages
            lastPage
            perPage
            total
        }
    }
}${FRAGMENT_RUN_LIST}`

export const RUN_BASIC = gql`query RUN_BASIC ($slug: String!) {
  runBasic(slug: $slug) {
    id
    identifier
    type
    texts {
      identifier
      languagecode
      text
    }
    files {
      id
      name
      url
      languagecode
      path
    }
    properties
  }
}`

export const GET_RUN = gql`query GET_RUN ($id: ID) {
    run (id: $id) {
        id
        domain {
            id
        }
        identifier
        version
        active
        dedicated_waves
        languagecode
        starts_at
        ends_at
        sticky
        checkin_type
        groupflow
        is_public
        shareable
        periodic
        remember_periodic_at
        properties
        type
        files {
            id
            slug
            name
            languagecode
            size
            crc
            path
            mime_type
            url
        }
        modules {
            id
            identifier
            duration
            status
            active
            keyvisual {
                id
                url
            }
            tags {
                category {
                    id
                    identifier
                }
                id
                identifier
                texts  {
                    identifier
                    languagecode
                    text
                }
            }
            texts {
                identifier
                languagecode
                text
            }
            type
            tracking_type
            languagecode
            properties
            created_at
            updated_at
            run_pivot {
                properties
            }
        }
        tags {
            category {
                id
                identifier
            }
            id
            identifier
            texts  {
                identifier
                languagecode
                text
            }
        }
        texts {
            id
            identifier
            languagecode
            text
        }
        users {
          id
          firstname
          lastname
          email
          invitation_sent
          run_evaluation {
            wave_id  
            status
            progress
            score
          }
        }
        waves {
            id
            starts_at
            ends_at
            texts {
                identifier
                languagecode
                text
            }
        }
    }
}`

export const GET_RUN_TEXTS_ONLY = gql`query GET_RUN_TEXTS_ONLY ($id: ID) {
    run (id: $id) {
        id
        identifier
        type
        languagecode
        texts {
            identifier
            languagecode
            text
        }
    }
}`

export const LIST_SESSIONS = gql`query listSessions (
    $run_id: ID!,
    $filter: FilterInput!,
    $first: Int!,
    $page: Int,
    $orderBy: [OrderByClause!]
) {
    sessions (run_id: $run_id, filter: $filter, first: $first, page: $page, orderBy: $orderBy, hasTrackingStatus: {}) {
        data {
            token
            status
            data
            module {
                identifier
                texts {
                    identifier
                    text
                    languagecode
                }
            }
            tracking_status {
                bookmark
                data
                objectives
                progress
                score
                status
                created_at
                updated_at
                completed_at
            }
            users {
              email
              username
            }
            created_at
            updated_at
        },
        paginatorInfo {
            count
            currentPage
            hasMorePages
            lastPage
            perPage
            total
        }
    }
}`

export const SESSION_STARTED = gql`subscription sessionStarted ($run_id: ID!, $module_id: ID!) {
    sessionStarted (run_id: $run_id, module_id: $module_id) {
        ...SessionFull
        users {
          email
        }
        created_at
    }
}${FRAGMENT_SESSION}`

export const SESSION_UPDATED = gql`subscription sessionUpdated ($run_id: ID!, $module_id: ID!) {
  sessionUpdated (run_id: $run_id, module_id: $module_id) {
    ...SessionFull
    users {
      email
    }
    created_at      
  }
}${FRAGMENT_SESSION}`

export const SESSION_REVOKED = gql`subscription sessionRevoked ($run_id: ID!, $module_id: ID!) {
  sessionRevoked (run_id: $run_id, module_id: $module_id) {
    ...SessionFull
    users {
      email
    }
    created_at
  }
}${FRAGMENT_SESSION}`

export const SESSION_TRACKING_STORED = gql`subscription sessionTrackingStored ($run_id: ID!, $module_id: ID!) {
  sessionTrackingStored (run_id: $run_id, module_id: $module_id) {
    ...SessionFull
    users {
      email
    }
    created_at
  }
}${FRAGMENT_SESSION}`

export const RUN_SESSIONS = gql`query RUN_SESSIONS ($run_id: ID!, $user_id: ID!) {
    runSessions (run_id: $run_id, user_id: $user_id) {
       token
       module {
          id
          type
          texts {
              identifier
              text
              languagecode
          }
          created_at
       }
       tracking_status {
          score
          progress
          status
          data
          created_at,
          started_at,
          completed_at
       }
    }
}`

export const CREATE_RUN = gql`mutation CREATE_RUN ($data: NewRunInput!) {
    createRun (data: $data) {
        id
        identifier
        active
        dedicated_waves
        starts_at
        ends_at
        sticky
        checkin_type
        groupflow
        is_public
        languagecode
        modules {
            id
            identifier
            duration
            keyvisual {
                id
                url
            }
            status
            tags {
                category {
                    id
                    identifier
                }
                id
                identifier
                texts  {
                    identifier
                    languagecode
                    text
                }
            }
            texts {
                identifier
                languagecode
                text
            }
            type
            tracking_type
            run_pivot {
                properties
            }
        }
        tags {
            category {
                id
                identifier
            }
            id
            identifier
            texts  {
                identifier
                languagecode
                text
            }
        }
        texts {
            identifier
            languagecode
            text
        }
        keyvisual {
            id
            url
        }
    }
}`

export const UPDATE_RUN = gql`mutation UPDATE_RUN ($data: UpdateRunInput!) {
    updateRun (data: $data) {
        id
        identifier
        active
        dedicated_waves
        starts_at
        ends_at
        sticky
        checkin_type
        groupflow
        is_public
        languagecode
        modules {
            id
            identifier
            duration
            keyvisual {
                id
                url
            }
            status
            tags{
                category {
                    id
                    identifier
                }
                id
                identifier
                texts  {
                    identifier
                    languagecode
                    text
                }
            }
            texts {
                identifier
                languagecode
                text
            }
            type
            tracking_type
            run_pivot {
                properties
            }
        }
        properties
        tags{
            category {
                id
                identifier
            }
            id
            identifier
            texts  {
                identifier
                languagecode
                text
            }
        }
        texts {
            identifier
            languagecode
            text
        }
        keyvisual {
            id
            url
        }
    }
}`

export const DELETE_RUN = gql`mutation DELETE_RUN ($id: ID!) {
    deleteRun(id: $id) { id }
}`

export const DUPLICATE_RUN = gql`mutation DUPLICATE_RUN ($id: ID!) {
    duplicateRun(id: $id) { id }
}`

export const SEND_NOTIFICATION = gql`mutation SEND_NOTIFICATION ($data: SendNotificationInput!) {
    sendNotification(data: $data)
}`

const FRAGMENT_RUN_DASHBOARD_FULL = gql`fragment RUN_DASHBOARD_FULL on RunDashboard {
    status
    run {
        id
        identifier
        version
        type
        active
        dedicated_waves
        starts_at
        ends_at
        created_at
        updated_at
        sticky
        checkin_type
        groupflow
        is_public
        active
        properties
        languagecode
        owner {
          email
          firstname
          lastname
        }
        texts {
            identifier
            languagecode
            text
        }
        tags {
          id
          category {
            id 
            identifier
          }
          identifier
          texts {
            id
            identifier
            languagecode
            text
          }
        }
        files {
            languagecode
            url
            name
            path
        }
        modules {
            id
            identifier
            duration
            active
            type
            languagecode
            tracking_type
            activates_at
            expires_at
            texts {
                identifier
                languagecode
                text
            }
            keyvisual {
                id
                url
            }
            tags {
                category {
                    id
                    identifier
                }
                id
                identifier
                texts  {
                    identifier
                    languagecode
                    text
                }
            }
            downloads {
                id
                masterFile {
                    id
                    url
                    filename
                    languagecode
                    size
                    mime_type
                }
                files {
                    id
                    url
                    filename
                    languagecode
                    size
                    mime_type
                }
            }
            links {
                id
                is_master
                languagecode
                url
            }
            packages {
                id
                is_master
                languagecode
                path
                starter_file
                type
            }
            properties
            run_pivot {
                properties
            }
        }
        waves {
            id
            starts_at
            ends_at
            participant_count
            participant_max
            texts {
                identifier
                languagecode
                text
            }
        }
    }
    configuration {
        alerts
        modules
        show_registration
        status
        user {
            wave {
                id
                identifier
                starts_at
                ends_at
            }
            progress
            score
            status
        }
    }
}`

export const RUN_DASHBOARD = gql`query RUN_DASHBOARD ($slug: String!) {
    runDashboard(slug: $slug) {
        ...RUN_DASHBOARD_FULL
    }
}
${FRAGMENT_RUN_DASHBOARD_FULL}`

export const RUN_DASHBOARD_MINIMAL = gql`query RUN_DASHBOARD_MINIMAL ($slug: String!) {
   runDashboard(slug: $slug) {
      status
      run {
        id
        identifier
        type
        active
        languagecode
        texts {
            identifier
            languagecode
            text
        }
        tags {
          id
          category {
            id 
            identifier
          }
          identifier
          texts {
            id
            identifier
            languagecode
            text
          }
        }
      }
      configuration {
        alerts
        modules
        show_registration
        status
        user {
            status
        }
      }
   }
}`

export const RUN_DASHBOARD_SESSION = gql`query RUN_DASHBOARD_SESSION ($run_id: ID!, $module_id: ID) {
    runDashboardSessions(run_id: $run_id, module_id: $module_id) {
        status
        module {
            id
            identifier
        }
        tracking_status {
            data
            progress
            score
            status
        }
    }
}`

export const LIST_PARTICIPANT_EVENTS = gql`query LIST_PARTICIPANT_RUNS {
    participantDashboard {
        status
        events {
            upcoming {
                ...RUN_DASHBOARD_FULL
            }
            available {
                ...RUN_DASHBOARD_FULL
            }
            invited {
                ...RUN_DASHBOARD_FULL
            }
            attending {
                ...RUN_DASHBOARD_FULL
            }
            completed {
                ...RUN_DASHBOARD_FULL
            }
            expired {
                ...RUN_DASHBOARD_FULL
            }
        }
    }
}
${FRAGMENT_RUN_DASHBOARD_FULL}`

export const LIST_PARTICIPANT_COURSES = gql`query LIST_PARTICIPANT_COURSES {
    participantDashboard {
        status
        courses {
            upcoming {
                ...RUN_DASHBOARD_FULL
            }
            available {
                ...RUN_DASHBOARD_FULL
            }
            invited {
                ...RUN_DASHBOARD_FULL
            }
            attending {
                ...RUN_DASHBOARD_FULL
            }
            completed {
                ...RUN_DASHBOARD_FULL
            }
            expired {
                ...RUN_DASHBOARD_FULL
            }
        }
    }
}
${FRAGMENT_RUN_DASHBOARD_FULL}`

export const LEARNING_PATHS = gql`query LEARNING_PATHS {
    participantDashboard {
        status
        learning_paths {
            learning_path {
                id
                domain {
                    id
                    identifier
                }
                identifier
                duration
                priority
                mandatory_level
                properties 
                runs {
                    id
                    identifier
                    version
                    status
                    texts {
                        identifier
                        text
                        languagecode
                    }
                }
                texts {
                    id
                    identifier
                    text
                    languagecode
                }
                files {
                    slug
                    name
                    filename
                    mime_type
                    path
                    url
                }
                tags {
                    id
                    identifier
                    texts {
                        id
                        identifier
                        text
                        languagecode
                    }
                }
            }
            run_dashboards {
                status
                run {
                    id
                    identifier
                    version
                    type
                    texts {
                        id
                        identifier
                        text
                        languagecode
                    }
                }
                configuration {
                    status
                    modules
                    show_registration
                    user {
                        status
                        score
                        progress
                    }
                }
            }
        }
    }
}`

export const LIST_PARTICIPANT_COURSES_WIDGET = gql`query LIST_PARTICIPANT_COURSES_WIDGET {
    participantDashboard {
        status
        courses {
            attending {
                run {
                    id
                    identifier
                    type
                    starts_at
                    ends_at
                    active
                    texts {
                        identifier
                        languagecode
                        text
                    }
                    files {
                        id
                        url
                        languagecode
                        name
                        path
                    }
                    modules {
                        id
                        identifier
                        status
                        type
                        duration
                    }
                }
                configuration {
                  status
                  user {
                    progress
                    status
                  }
                }
            }
        }
    }
}`

export const RUN_TRACKING_HISTORY = gql`query RUN_TRACKING_HISTORY (
    $run_id: ID!
    $filter: FilterInput!,
    $first: Int!,
    $page: Int,
    $orderBy: [OrderByClause!],
    $orderByRelation: OrderByRelationClause
) {
  runTrackingHistory(
      run_id: $run_id,
      filter: $filter,
      first: $first,
      page: $page,
      orderBy: $orderBy,
      orderByRelation: $orderByRelation
  ) {
    data {
      id
      email
      firstname
      lastname
      username
      invitation_sent
      languagecode
      tags {
        id
        identifier
        category {
          id
          identifier
        }
        texts {
          identifier
          languagecode
          text
        }
      }
      run_evaluation {
        wave_id
        status
        score
        progress
        started_at
        completed_at
      }
    }
    paginatorInfo {
      count
      currentPage
      hasMorePages
      lastPage
      perPage
      total
    }
  }
}`

export const RUN_POTENTIAL_USERS = gql`query RUN_POTENTIAL_USERS (
    $run_id: ID!
    $filter: FilterInput!,
    $first: Int!,
    $page: Int,
    $orderBy: [OrderByClause!]
) {
    runPotentialUsers(
        run_id: $run_id,
        filter: $filter,
        first: $first,
        page: $page,
        orderBy: $orderBy
    ) {
        data {
            id
            email
            firstname
            lastname
            username
            updated_at
            languagecode
            tags {
                id
                identifier
                category {
                    id
                    identifier
                }
                texts {
                    identifier
                    languagecode
                    text
                }
            }
        }
        paginatorInfo {
            count
            currentPage
            hasMorePages
            lastPage
            perPage
            total
        }
    }
}`

export const TRACKINGS = gql`query TRACKINGS ($data:[TrackingQueryInput!]) {
    trackings (data: $data) {
        run_id
        module_id
        objective
        site
        key
        properties
        tracking_data {
            created_at
            data
            files {
                crc
                created_at
                filename
                id
                languagecode
                mime_type
                name
                path
                size
                slug
                thumbnail
                updated_at
                url
            }
            id
            key
            objective
            properties
            score
            session_token
            site
            status
            texts {
                created_at
                head
                id
                identifier
                languagecode
                text
                updated_at
            }
            type
            updated_at
        }
    }
}`

export const RUN_UPDATED = gql`subscription runUpdated ($run_id: ID!) {
    runUpdated (run_id: $run_id) {
        id
        active
        properties
        modules {
            id
            active
            identifier
            duration
            status
            keyvisual {
                id
                url
            }
            tags {
                category {
                    id
                    identifier
                }
                id
                identifier
                texts  {
                    identifier
                    languagecode
                    text
                }
            }
            texts {
                identifier
                languagecode
                text
            }
            type
            tracking_type
            languagecode
            created_at
            updated_at
            run_pivot {
                properties
            }
        }
    }
}`

export const FILES = gql`query files (
    $filter: FilterInput!,
    $target_type: String,
    $first: Int!,
    $page: Int,
    $orderBy: [OrderByClause!]) {
    files (filter: $filter, target_type: $target_type, first: $first, page: $page, orderBy: $orderBy) {
        data {
            id
            slug
            name
            languagecode
            filename
            path
            mime_type
            crc
            size
            url
            thumbnail
        }
    }
}`

export const LIST_PARTICIPANTS = gql`query LIST_PARTICIPANTS (
    $filter: FilterInput!,
    $first: Int!,
    $page: Int,
    $orderBy: [OrderByClause!]
) {
    participants (filter: $filter, first: $first, page: $page, orderBy: $orderBy) {
        data {
            ...UserWithoutPermissions
        },
        paginatorInfo {
            count
            currentPage
            perPage
            total
        }
    }
}
${FRAGMENT_USER_WITHOUT_PERMISSIONS}`

export const ALLOCATE_PARTICIPANTS = gql`mutation ALLOCATE_PARTICIPANTS ($data: AllocateParticipantsInput!) {
    allocateParticipants(data: $data) {
        users {
            user {
                id
            }
        }
    }
}`

export const ISSUE_CERTIFICATE = gql`query ISSUE_CERTIFICATE ($run_id: ID!, $user_id: ID!) {
    issueCertificate (run_id: $run_id, user_id: $user_id)
}`

export const CLEAR_SESSION_TRACKINGS = gql`mutation CLEAR_SESSION_TRACKINGS ($data: ClearSessionTrackingsInput!) {
    clearSessionTrackings(data: $data)
}`
