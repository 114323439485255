export async function unsavedChanges (context: Vue): Promise<boolean> {
  return await context.$confirm({
    x: 'right',
    y: 'top',
    message: context.$t('core.message.unsavedChanges'),
    buttons: [{
      text: context.$t('core.global.yes'),
      type: 'outlined',
      answer: false
    }, {
      text: context.$t('core.global.no'),
      answer: true
    }]
  })
}

export async function deleteConfirm (context: Vue, name: string): Promise<boolean> {
  return await context.$confirm({
    color: 'error',
    message: context.$t('core.message.deleteConfirm', [name]),
    buttons: [{
      text: context.$t('core.action.cancel'),
      type: 'outlined',
      answer: false
    }, {
      text: context.$t('core.action.delete'),
      color: 'error',
      answer: true
    }]
  })
}
