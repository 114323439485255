




























import mixins from 'vue-typed-mixins'
import UserSettings from '@simpl/core/mixins/utils/UserSettings'

export default mixins(UserSettings('global', [
  'preferTabOverNewWindow',
  'darkTheme'
], true).extend({
  name: 'Preferences',

  data () {
    return {
      preferTabOverNewWindow: false,
      darkTheme: true
    }
  },

  methods: {
    toggleDarkMode () {
      this.$vuetify.theme.dark = this.darkTheme
    }
  }
}))
