import Vue from 'vue'
import VueRouter, { Route, RouteConfig } from 'vue-router'
import RegisterView from '@simpl/auth/views/Register.vue'
import LoginView from '@simpl/auth/views/Login.vue'
import DashboardView from '@simpl/core/views/Dashboard.vue'
import RejectInvitation from '@simpl/auth/views/RejectInvitation.vue'
import Logout from '@simpl/auth/views/Logout.vue'

import store from '../store'
import { generateDialogRoutes } from '../../utils'
import { sleep } from '../../utils/core'
import { ACTIONS } from '@simpl/auth/store/consts'
import { isDemoAvailableInNamespace } from '../../utils/env'

Vue.use(VueRouter)

const demoRoutes = isDemoAvailableInNamespace
  ? [{
      path: '/demo',
      name: 'demo',
      component: RegisterView,
      meta: {
        guest: true,
        appBarComponent: null
      }
    },
    ...generateDialogRoutes(RegisterView, { dialog: true, guest: true, appBarComponent: null }, 'demo')]
  : []

const routes = [{
  path: '/',
  name: 'home',
  component: DashboardView
},
...generateDialogRoutes(DashboardView, { dialog: true }),
...demoRoutes,
{
  path: '/login',
  name: 'login',
  component: LoginView,
  meta: {
    guest: true,
    appBarComponent: null
  }
},
...generateDialogRoutes(LoginView, { dialog: true, guest: true, appBarComponent: null }, 'login'),
{
  path: '/auto-login/:token',
  name: 'auto-login',
  component: LoginView,
  meta: {
    guest: true,
    appBarComponent: null
  }
},
{
  path: '/login-challenge',
  name: 'login-challenge',
  component: LoginView,
  meta: {
    guest: true,
    appBarComponent: null
  }
},
{
  path: '/password-reset/:email/:token',
  name: 'password-reset',
  component: LoginView,
  props: (route: any) => ({
    token: decodeURIComponent(route.params.token),
    email: route.params.email
  }),
  meta: {
    guest: true,
    appBarComponent: null
  }
}, {
  path: '/accept-invitation/:email/:token',
  name: 'accept-invitation',
  component: LoginView,
  props: (route: any) => ({
    token: decodeURIComponent(route.params.token),
    email: route.params.email
  }),
  meta: {
    guest: true,
    appBarComponent: null
  }
}, {
  path: '/reject-invitation/:email/:token',
  name: 'reject-invitation',
  component: RejectInvitation,
  props: (route: any) => ({
    token: decodeURIComponent(route.params.token),
    email: route.params.email
  }),
  meta: {
    guest: true,
    appBarComponent: null
  }
}, {
  path: '/verify-user/:id',
  name: 'verify-user',
  component: LoginView,
  props: (route: any) => ({
    userId: route.params.id
  }),
  meta: {
    guest: true,
    appBarComponent: null
  }
}, {
  path: '/verify-pending-user/:id',
  name: 'verify-pending-user',
  component: LoginView,
  props: (route: any) => ({
    userId: route.params.id
  }),
  meta: {
    guest: true,
    appBarComponent: null
  }
}, {
  path: '/logout',
  name: 'logout',
  component: Logout
}]

const createRouter = () => {
  return new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior () {
      return { x: 0, y: 0 }
    }
  })
}

const router = createRouter()

router.beforeEach(async (to, from, next) => {
  const requiresAuth = !to.meta?.guest
  const loggedIn = !!store.state.auth.token && (!store.state.auth.user.tfa_enabled || !!store.state.auth.twoFactorToken)
  const isDialog = to.meta?.dialog

  if (!store.state._booted) {
    while (!store.state._booted) {
      await sleep(50)
    }
    next({
      path: to.path,
      replace: true
    })
    return
  }

  if (isDialog && loggedIn !== requiresAuth) {
    const dialogName = to.name!.split('.').reverse()[0]
    next({ name: loggedIn ? dialogName : `login.${dialogName}` })
    return
  }

  if (requiresAuth && !loggedIn) {
    next('login')
    return
  }

  if (loggedIn && to.path === '/login') {
    next({
      path: '/',
      replace: true
    })
    return
  }

  if (loggedIn) {
    store.dispatch(`auth/${ACTIONS.CHECK_DOMAIN_STATUS}`).then(() => {})
  }

  next()
})

export const resetRoutes = () => {
  const newRouter = createRouter();
  (router as any).matcher = (newRouter as any).matcher
}

export const addRoutes = (routes: RouteConfig[]) => {
  routes.forEach(route => router.addRoute(route))
}

export default router
