import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client/core'
import { createUploadLink } from 'apollo-upload-client'
import PusherLink from './pusher-link'
import VueApollo from 'vue-apollo'
import { buildAxiosFetch } from '@lifeomic/axios-fetch'
import Vue from 'vue'
import { errorLink } from './error-link'
import { axios } from '../axios'
import { AppModuleTheme } from '../../types/graphql'

Vue.use(VueApollo)

export const apolloCache = new InMemoryCache({
  typePolicies: {
    ParticipantDashboard: {
      keyFields: []
    },
    Theme: {
      fields: {
        app_modules: {
          merge (existing: AppModuleTheme[] = [], incoming: AppModuleTheme[]) {
            return [...incoming]
          }
        }
      }
    },
    Run: {
      fields: {
        modules: {
          merge (_, incoming: unknown) {
            return incoming
          }
        }
      }
    },
    RunRestricted: {
      fields: {
        modules: {
          merge (_, incoming: unknown) {
            return incoming
          }
        }
      }
    }
  }
})

const uploadLink = createUploadLink({
  uri: process.env.VUE_APP_GRAPHQL_HTTP_ENDPOINT,
  fetch: buildAxiosFetch(axios as any, ((config: any, input: any, init: any) => ({
    ...config,
    onUploadProgress: init.onUploadProgress,
    cancelToken: init.cancelToken
  })) as any) as any,
  isExtractableFile: ((value: any) => {
    const iFrame = document.querySelector('iframe')
    const iFrameFile = (iFrame?.contentWindow as any)?.File || File
    const iFrameBlob = (iFrame?.contentWindow as any)?.File || Blob
    return (typeof File !== 'undefined' && value instanceof File) ||
           (typeof Blob !== 'undefined' && value instanceof Blob) ||
           (typeof iFrameFile !== 'undefined' && value instanceof iFrameFile) ||
           (typeof iFrameBlob !== 'undefined' && value instanceof iFrameBlob)
  }) as any
})

const echoLink = new PusherLink()

const apolloClient = new ApolloClient({
  link: ApolloLink.from([errorLink, echoLink, uploadLink as any]),
  cache: apolloCache,
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all'
    },
    query: {
      errorPolicy: 'all'
    }
  },
  connectToDevTools: true
})

export const apolloProvider = new VueApollo({
  // FIXME: @sk fix typings
  defaultClient: apolloClient as any
})
