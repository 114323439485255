import Vue from 'vue'

let id = 0

export default Vue.extend({
  name: 'NotificationCenter',

  props: {
    group: String,
    positionX: String,
    positionY: String,
    horizontal: Boolean,
    reverse: Boolean,
    zIndex: {
      type: [String, Number],
      default: 500
    }
  },

  data () {
    return {
      notifications: [] as any[]
    }
  },

  computed: {
    flexDirection (): string {
      return `${this.horizontal ? 'row' : 'column'}${this.reverse ? '-reverse' : ''}`
    },
    styles (): Record<string, any> {
      return {
        position: 'fixed',
        display: 'flex',
        flexDirection: this.flexDirection,
        zIndex: this.zIndex,
        left: this.positionX === 'left' ? 0 : (this.positionX !== 'right' ? '50%' : undefined),
        right: this.positionX === 'right' ? 0 : undefined,
        top: this.positionY === 'top' ? 0 : undefined,
        bottom: this.positionY !== 'top' ? 0 : undefined,
        transform: ['left', 'right'].includes(this.positionX) ? undefined : 'translateX(-50%)',
        alignItems: 'flex-start'
      }
    }
  },

  created () {
    this.$notification.subscribe(this.group, this.addNotification)
  },

  methods: {
    addNotification (notification: Record<string, any>) {
      const nId = ++id
      this.notifications.push({ ...notification, __id: nId })

      const timeout = typeof notification.timeout === 'undefined' ? 5000 : notification.timeout
      if (timeout > 0) {
        window.setTimeout(() => {
          this.notifications = this.notifications.filter(n => n.__id !== nId)
        }, timeout)
      }
    }
  },

  render (h) {
    return h('div', {
      staticClass: 'notification-center',
      class: [{ reverse: this.reverse }],
      style: (this as any).styles
    }, (this as any).notifications.map((n: any) => this.$scopedSlots.default?.({
      key: n.__id,
      notification: n,
      remove: () => {
        this.notifications = this.notifications.filter(notification => notification.__id !== n.__id)
      }
    })))
  }
})
