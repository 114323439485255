


















































import mixins from 'vue-typed-mixins'
import { Tag } from '../../types/graphql'
import { browserLanguageShort, getTextForTemporaryUserLanguage } from '../../utils'
import i18n from '../../plugins/i18n'
import { MUTATIONS } from '@simpl/auth/store/consts'
import { mapMutations } from 'vuex'
import MobileDetection from '../../mixins/utils/MobileDetection'

export default mixins(MobileDetection).extend({
  name: 'LanguageDropdown',

  data () {
    return {
      show: false,
      selectedLanguage: null! as Tag[]
    }
  },

  computed: {
    availableLanguages (): Tag[] | null {
      return this.$store.state._availableLanguages || null
    },
    currentUserLanguage: {
      get (): Tag | null {
        const languagecode = this.$store.state.auth?.temporaryLanguage || browserLanguageShort

        return this.availableLanguages?.find(language => language.identifier === languagecode)! || null
      },
      set (v: Tag) {
        this.$store.commit(`auth/${MUTATIONS.SET_TEMPORARY_LANGUAGE}`, { temporaryLanguage: v.identifier })
      }
    },
    slimMode (): Boolean {
      return this.$vuetify.breakpoint.smAndDown
    }
  },

  watch: {
    currentUserLanguage: {
      immediate: true,
      handler (v) {
        if (!v) return

        i18n.locale = v.identifier
      }
    }
  },

  beforeDestroy () {
    this.setTemporaryLanguage({ temporaryLanguage: null })
  },

  methods: {
    ...mapMutations('auth', ['setTemporaryLanguage']),
    getTextForTemporaryUserLanguage
  }
})
