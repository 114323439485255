import Vue from 'vue'
import { loadLocalizations } from '../utils/load-localizations'

export async function importLocalizations (app: Vue) {
  await loadLocalizations(
    app,
    () => require.context('./', true, /[a-z0-9-_]+\.(json|ts)$/i),
    async (localizations: Record<string, any>) => {
      for (const locale in localizations) {
        try {
          localizations[locale].$vuetify = (await import(`vuetify/lib/locale/${locale}.js`)).default
        } catch (_) {}
      }
    }
  )
}
