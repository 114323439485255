<template>
  <v-dialog
    v-model="dialog"
    width="100%"
    max-width="800px"
    scrollable
    persistent
    :fullscreen="$vuetify.breakpoint.xs"
  >
    <template #activator="{ on }">
      <slot
        v-bind="{ on }"
      ></slot>
    </template>

    <v-card tile>
      <v-card-title class="align-stretch flex-column">
        <Headline
          color="primary"
          class="display-1"
        >
          PLANS {{ $t('plansDialog.headline') }}
        </Headline>
        <div
          v-if="domain.is_demo && domain.payment_status !== 'demo-expired'"
          class="body-1"
        >
          {{ statusDemoText }}
        </div>
        <div
          v-if="domain.payment_status == 'expired'"
          class="body-1"
        >
          {{ $t('plansDialog.statusExpired') }}
        </div>
        <div
          v-else-if="domain.payment_status == 'demo-expired'"
          class="body-1"
        >
          {{ $t('plansDialog.statusDemoExpired') }}
        </div>
      </v-card-title>
      <v-card-text>
        <h2
          class="mt-4 mb-4"
        >
          {{ $t('plansDialog.headline1') }}
        </h2>
        <p>
          {{ body1Text }}
        </p>
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="6"
            sm="6"
          >
            <v-label><b>Susanne Tölzel</b> ({{ $t('plansDialog.position1') }})</v-label>
            <v-btn
              text
              class="pl-0"
              @click="openPhoneLink('49661949495')"
            >
              <v-icon class="pr-2">
                mdi-phone
              </v-icon>
              +49 661 94 94 - 95
            </v-btn>
            <v-btn
              text
              class="pl-0"
              @click="openMail('s.toelzel@innovationmecom.de')"
            >
              <v-icon class="pr-2">
                mdi-email
              </v-icon>
              s.toelzel@innovationmecom.de
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="6"
            sm="6"
          >
            <v-label><b>Rudi Panek</b> ({{ $t('plansDialog.position2') }})</v-label>
            <v-btn
              text
              class="pl-0"
              @click="openPhoneLink('49661949445')"
            >
              <v-icon class="pr-2">
                mdi-phone
              </v-icon>
              +49 661 94 94 - 45
            </v-btn>
            <v-btn
              text
              class="pl-0"
              @click="openMail('r.panek@innovationmecom.de')"
            >
              <v-icon class="pr-2">
                mdi-email
              </v-icon>
              r.panek@innovationmecom.de
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <!--
    <v-row>
      <v-col>
        <v-card
          class="mx-auto"
          max-width="344"
        >
          <v-img
            src="https://data.whicdn.com/images/338738903/original.jpg"
            height="100px"
          />

          <v-card-title>
            Small Business Plan
          </v-card-title>

          <v-card-subtitle>
            Die und die Funktion weniger, aber trotzdem auch ne gute Wahl.
          </v-card-subtitle>

          <v-card-actions>
            <v-btn
              text
              @click="openMail('Small%20Business')"
            >
              Vertrieb kontaktieren
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col>
        <v-card
          class="mx-auto"
          max-width="344"
        >
          <v-img
            src="https://data.whicdn.com/images/338738903/original.jpg"
            height="100px"
          />

          <v-card-title>
            Business Plan
          </v-card-title>

          <v-card-subtitle>
            Die und die Funktion und überhaupt auch ne gute Wahl.
          </v-card-subtitle>

          <v-card-actions>
            <v-btn
              text
              @click="openMail('Business')"
            >
              Vertrieb kontaktieren
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col>
        <v-card
          class="mx-auto"
          max-width="344"
        >
          <v-img
            src="https://data.whicdn.com/images/338738903/original.jpg"
            height="100px"
          />

          <v-card-title>
            Premium Plan
          </v-card-title>

          <v-card-subtitle>
            Alle Funktionen in unbegrenztem Umfang nutzen.
          </v-card-subtitle>

          <v-card-actions>
            <v-btn
              text
              @click="openMail('Premium')"
            >
              Vertrieb kontaktieren
            </v-btn>
          </v-card-actions>
        </v-card>
          </v-col>
        </v-row>
        -->

      <v-card-actions>
        <v-btn
          class="tt-upper"
          color="primary"
          @click="openMail('vertrieb@simpl.training')"
        >
          {{ $t('plansDialog.btnContact') }}
        </v-btn>
        <v-spacer />
        <v-btn
          class="tt-upper"
          @click="cancel"
        >
          {{ $t('core.action.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { MUTATIONS } from '@simpl/auth/store/consts'

  export default {
    name: 'PlansDialog',

    model: {},

    props: {
      value: Boolean
    },

    data: () => ({
    }),

    computed: {
      dialog: {
        get () {
          return this.value
        },
        set (v) {
          this.$emit('input', v)
        }
      },
      domain () {
        let domain = {
          is_demo: false,
          trial_ends_at: null,
          payment_status: ''
        }
        if (this.$store.state.auth.domain) {
          domain = this.$store.state.auth.domain
        }
        return domain
      },
      getTrialDaysLeft () {
        const today = new Date()
        const nextMonth = new Date()
        nextMonth.setMonth(nextMonth.getMonth() + 1)
        const trialEndsAt = this.domain.trial_ends_at ? new Date(this.domain.trial_ends_at).getTime() : nextMonth.getTime()
        return Math.ceil((trialEndsAt - today.getTime()) / 1000 / 60 / 60 / 24)
      },
      getTrialEndDateFormatted () {
        const nextMonth = new Date()
        nextMonth.setMonth(nextMonth.getMonth() + 1)
        const trialEndsAt = this.domain.trial_ends_at ? new Date(this.domain.trial_ends_at) : nextMonth
        return trialEndsAt.toLocaleString().substr(0, trialEndsAt.toLocaleString().indexOf(','))
      },
      statusDemoText () {
        return this.$t('plansDialog.statusDemo' + this.getProductType(), [this.getTrialDaysLeft, this.getTrialEndDateFormatted])
      },
      body1Text () {
        return this.$t('plansDialog.body1' + this.getProductType(), [this.getTrialDaysLeft, this.getTrialEndDateFormatted])
      }
    },

    methods: {
      openMail (to = 'vertrieb@simpl.training') {
        // TODO: localize texts
        const body = 'Sehr%20geehrte%20Damen%20und%20Herren%0A%0AIch%20interessiere%20mich%20für%20ihr%20Produkt%20und%20bitte%20Sie%20um%20ein%20Angebot%20(Domain:%20' + this.$store.state.auth.domain.namespace + ').%0A%0AMit%20freundlichen%20Grüßen%0A%0A'
        location.href = 'mailto:' + to + '?subject=Angebot%20zu%20' + this.getProductNameFromType() + '&body=' + body
      },
      openPhoneLink (num) {
        window.open('tel:+' + num, '_blank')
      },
      openWebLink () {
        window.open('https://www.innovationmecom.de', '_blank')
      },
      cancel () {
        this.$store.commit(`auth/${MUTATIONS.SET_SHOW_PLANS_POPUP}`, false)
      },
      getProductNameFromType () {
        const type = this.getProductType()
        let name = 'simpL'
        if (type === 'CS') {
          name = 'Content Shaker'
        }
        if (type === '2Go') {
          name = '2GO Learning Box'
        }
        return name
      },
      getProductType () {
        if (!this.$store.state.auth.domain?.parent) {
          return ''
        }
        if (this.$store.state.auth.domain.parent.namespace === 'contentshaker') {
          return 'CS'
        }
        if (this.$store.state.auth.domain.parent.namespace === 'learningbox') {
          return '2Go'
        }
        return ''
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
