import Vue from 'vue'
import store from '../store'
import { Role } from '@simpl/core/types/graphql'

export function setPermissions () {
  const { user } = store.state.auth

  Vue.prototype.$permission.reset()

  if (!user) return

  const roles = user.roles as Role[]

  roles && roles.forEach(role => {
    Vue.prototype.$permission.setRoles(role.identifier)
    role.permissions!.forEach(permission => {
      Vue.prototype.$permission.set(permission!.type, permission!.ability)
    })
  })
}
