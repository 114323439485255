














































import Vue from 'vue'
import { getTextForLanguage } from '@simpl/core/utils'
import { TranslateResult } from 'vue-i18n'

export default Vue.extend({
  name: 'Welcome',

  computed: {
    headline (): string | TranslateResult {
      const textFromDomain = getTextForLanguage(this.$store.state.auth.domain, this.$i18n.locale, 'welcome.headline', 'welcome.headline')
      return (textFromDomain !== 'welcome.headline') ? textFromDomain : this.$t('welcome.headline')
    },
    text (): string | TranslateResult {
      const textFromDomain = getTextForLanguage(this.$store.state.auth.domain, this.$i18n.locale, 'welcome.text', 'welcome.text')
      return (textFromDomain !== 'welcome.text') ? textFromDomain : this.$t('welcome.welcomeText')
    }
  }
})
