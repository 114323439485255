




import Vue from 'vue'
import { ACTIONS } from '../store/consts'

export default Vue.extend({
  name: 'Logout',

  async mounted () {
    await this.$store.dispatch(`auth/${ACTIONS.LOGOUT}`)
    await this.$router.replace('/login')
  }
}
)
