

















































































































import { REQUEST_SUPPORT } from '../../graphql'
import ActiveDomainTags from '../../mixins/apollo/ActiveDomainTags'
import ValidationRules from '../../mixins/utils/ValidationRules'
import mixins from 'vue-typed-mixins'
import { RequestSupportInput } from '../../types/graphql'

export default mixins(ActiveDomainTags, ValidationRules).extend({
  name: 'ContactDialog',

  data () {
    return {
      dialog: false,
      loading: false,
      name: '',
      email: '',
      phoneNumber: null! as string,
      selectedMarketId: null! as string,
      message: '',
      valid: true,
      requestType: 'support',
      requestTypes: [{
        text: this.$t('contactDialog.requestTypes.support'),
        value: 'support'
      }, {
        text: this.$t('contactDialog.requestTypes.registration'),
        value: 'registration'
      }],
      alertOptions: {
        border: 'left',
        message: this.$t('core.message.messageSent'),
        type: 'success',
        color: 'success'
      }
    }
  },

  computed: {
    contactFormValid (): boolean {
      return this.valid && (this.isRegisterRequest || (!!this.requestType && !!this.message)
      )
    },
    isRegisterRequest (): boolean {
      return this.requestType === 'registration'
    },
    requiredIfNotRegisterRequest (): boolean {
      return this.isRegisterRequest || !!this.message
    },
    hideTelephoneInput (): boolean {
      return this.$store.state.auth.domain?.properties?.hideContactPhoneInput
    },
    activeDomainMarketTagsFiltered () {
      // international (-) and KBU not available in support dialog
      return this.activeDomainMarketTags.filter((tag) => tag.identifier !== '-' && tag.identifier !== 'KBU')
    }
  },

  watch: {
    requestType (v) {
      if (v === 'support') {
        if (!this.message || this.message === this.$t('contactDialog.autoMessages.registration')) {
          this.message = this.$t('contactDialog.autoMessages.support') as string
        }
      } else if (v === 'registration') {
        if (!this.message || this.message === this.$t('contactDialog.autoMessages.support')) {
          this.message = this.$t('contactDialog.autoMessages.registration') as string
        }
      }
    }
  },

  created () {
    if (this.activeDomainMarketTags?.length === 1) {
      this.selectedMarketId = this.activeDomainMarketTags[0].id
    }
  },

  methods: {
    getMutationData (): { data: RequestSupportInput } {
      let message = this.$t(`contactDialog.requestTypes.${this.requestType}`)

      if (this.message) {
        message += `. ${this.message}`
      }

      const data: RequestSupportInput = {
        name: this.name,
        email: this.email,
        phone: '',
        message: message as string
      }

      if (this.selectedMarketId) data.market_id = parseInt(this.selectedMarketId)
      if (this.phoneNumber) data.phone = this.phoneNumber

      return { data }
    },
    async send () {
      this.loading = true

      await this.$apollo.mutate({
        mutation: REQUEST_SUPPORT,
        variables: this.getMutationData()
      })

      this.loading = false

      this.cancel()
      this.$notification.publish('bottom', this.alertOptions)
    },
    cancel () {
      (this.$refs.form as any).reset()
      this.dialog = false
    }
  }
})
