




































































































































































































import mixins from 'vue-typed-mixins'
import PasswordValidator from '../mixins/PasswordValidator'
import ActiveDomainTags from '@simpl/core/mixins/apollo/ActiveDomainTags'
import ValidationRules from '@simpl/core/mixins/utils/ValidationRules'
import PasswordHints from './PasswordHints.vue'
import { USER_EXISTS } from '@simpl/access-control/graphql'
import { REGISTER_USER } from '../graphql'
import { isDev } from '@simpl/core/utils/env'
import { UserExists, RegisterUserInput } from '@simpl/core/types/graphql'
import { TranslateResult } from 'vue-i18n'
import { unsavedChanges } from '@simpl/core/utils/message'
import PrivacyConsentCheckbox from '@simpl/core/components/PrivacyConsentCheckbox.vue'

function initialState () {
  return {
    email: '',
    username: '',
    firstname: '',
    lastname: '',

    newPassword: '',
    confirmPassword: '',

    languagecode: '',
    marketId: '',

    termsAccepted: false,

    loading: false,
    valid: true,

    userExists: null! as UserExists,
    errorMessageEmailExists: '' as string | TranslateResult,
    errorMessageUsernameExists: '' as string | TranslateResult
  }
}

export default mixins(PasswordValidator, ActiveDomainTags, ValidationRules).extend({
  name: 'SelfRegisterDialog',

  components: { PasswordHints, PrivacyConsentCheckbox },

  model: {},

  props: {
    value: Boolean
  },

  data () {
    return {
      ...initialState()
    }
  },

  computed: {
    show: {
      get (): boolean {
        return this.value
      },
      set (v: boolean) {
        this.$emit('input', v)
      }
    },
    domainId (): string {
      if (isDev && window.location.hostname === 'localhost' && !this.$store.state.auth.domain?.id) {
        console.error('[SelfRegisterDialog] Can not access domain id in dev mode without VUE_APP_SIMULATED_HOST')
        return '1'
      }

      return this.$store.state.auth.domain.id
    },
    hasChanges (): boolean {
      return !!(this.email || this.firstname || this.lastname ||
        this.newPassword || this.confirmPassword || this.languagecode || this.marketId)
    },
    canRegister (): boolean {
      return this.valid && this.hasChanges
    },
    activeDomainMarketTagsFiltered () {
      // international (-) and KBU not available in self registration
      return this.activeDomainMarketTags.filter((tag) => tag.identifier !== '-' && tag.identifier !== 'KBU')
    }
  },

  watch: {
    show (v) {
      if (v) return

      (this.$refs.form as any).resetValidation()
      Object.assign(this.$data, {
        ...initialState()
      })
    }
  },

  methods: {
    async cancel () {
      this.show = this.hasChanges ? await unsavedChanges(this) : false
    },

    async emailExists (): Promise<void> {
      const exists = await this.$apollo.query({
        query: USER_EXISTS,
        variables: {
          type: 'email',
          query: this.email
        }
      })

      this.userExists = exists.data.userExists
      if (this.userExists?.exists) this.handleUserAlreadyExists('email')
    },
    async usernameExists (): Promise<void> {
      if (!this.username.trim().length) return

      const exists = await this.$apollo.query({
        query: USER_EXISTS,
        variables: {
          type: 'username',
          query: this.username
        }
      })

      this.userExists = exists.data.userExists
      if (this.userExists?.exists) this.handleUserAlreadyExists('username')
    },
    handleUserAlreadyExists (type: string) {
      const statusMsg = this.userExists.status!.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
        return g1.toUpperCase() + g2.toLowerCase()
      }).replace('-', '')

      if (type === 'email') {
        this.errorMessageEmailExists = this.$t(`user.error.alreadyExists${statusMsg}`)
      }

      if (type === 'username') {
        this.errorMessageUsernameExists = this.$t(`user.error.alreadyExists${statusMsg}`)
      }
    },

    async register () {
      if (!this.termsAccepted) return

      const variables: RegisterUserInput = {
        domain_id: this.domainId,
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        password: this.newPassword
      }

      if (this.username.length) {
        variables.username = this.username
      }

      if (this.languagecode) {
        variables.languagecode = this.languagecode
      }

      if (this.marketId) {
        variables.market_id = this.marketId
      }

      this.loading = true
      try {
        await this.$apollo.mutate({
          mutation: REGISTER_USER,
          variables: {
            data: variables
          }
        })

        this.$notification.publish('bottom', {
          message: this.$t('accessControl.message.registrationSuccessful'),
          color: 'success',
          type: 'success'
        })

        this.show = false
      } catch (error: any) {
        this.$notification.publish('bottom', {
          message: this.$t(error.message),
          color: 'error',
          type: 'error'
        })
      }
      this.loading = false
    }
  }
})
