














































import Vue from 'vue'

export default Vue.extend({
  name: 'DashboardTile',

  props: {
    icon: String,
    base64EncodedSrc: String,
    imageSrc: String,
    label: String,
    description: String,
    to: String,
    isSuper: Boolean,
    color: {
      type: String,
      default: 'white'
    }
  },

  computed: {
    computedImageSrc (): string {
      if (this.imageSrc) {
        return this.imageSrc
      }

      const b64 = window.btoa(this.base64EncodedSrc)
      return `data:image/svg+xml;base64,${b64}`
    }
  }
})
