import Vue from 'vue'

export interface EventBus {
  on (event: string, callback: Function): Vue
  emit (event: string, ...args: any[]): Vue
  off (event: string, callback: Function): Vue
}

const eventBus = new Vue()

const events = {
  on (event: string, callback: Function) {
    eventBus.$on(event, callback)
  },

  emit (event: string, ...args: any[]) {
    eventBus.$emit(event, ...args)
  },

  off (event: string, callback: Function) {
    eventBus.$off(event, callback)
  }
}

Vue.use(() => {
  Vue.prototype.$eventBus = events
})

declare module 'vue/types/vue' {
  interface Vue {
    $eventBus: EventBus
  }
}
