






























































import mixins from 'vue-typed-mixins'
import { Query, UserTrackingHistory } from '@simpl/core/types/graphql'
import { USER_TRACKING_HISTORY } from '@simpl/access-control/graphql'
import { getTextForUserLanguage } from '@simpl/core/utils'
import { getLocaleDateTimeFormat } from '@simpl/core/utils/date-time-format'
import RunTypes from '../../runs/mixins/RunTypes'

export default mixins(RunTypes).extend({
  name: 'Achievements',

  data () {
    return {
      achievements: [] as UserTrackingHistory[],
      loading: 0
    }
  },

  apollo: {
    achievements: {
      query: USER_TRACKING_HISTORY,

      fetchPolicy: 'cache-and-network',

      variables () {
        return {
          user_id: this.$store.state.auth.user.id,
          first: 9999,
          filter: {
            filterBy: [{
              name: 'status',
              values: ['completed', 'passed']
            }]
          },
          orderBy: [{
            column: 'completed_at',
            order: 'DESC'
          }]
        }
      },

      update (result: Query) {
        return result.userTrackingHistory!.data
      },

      error (error) {
        console.error(error)
      },

      loadingKey: 'loading'
    }
  },

  methods: {
    getTextForUserLanguage,
    getLocaleDateTimeFormat,

    getDateWithoutTime (timestamp: string) {
      return timestamp?.split(' ')[0] || ''
    }
  }
})
