import { DirectiveOptions } from 'vue'
import { getLongLocaleDateTimeFormat, getLocaleDateTimeFormat } from '../utils/date-time-format'

export default {
  bind: (el, binding, vNode) => {
    const targetProperty = binding.modifiers.html ? 'innerHTML' : 'innerText'

    if (vNode.context) {
      (el as any).__simplLocaleDateFormatWatcher = vNode.context?.$watch(() => {
        return binding.value
      }, (v) => {
        el[targetProperty] = binding.modifiers.long
          ? getLongLocaleDateTimeFormat(v)
          : getLocaleDateTimeFormat(v)
      }, {
        deep: true,
        immediate: true
      })
    } else {
      el[targetProperty] = binding.modifiers.long
        ? getLongLocaleDateTimeFormat(binding.value)
        : getLocaleDateTimeFormat(binding.value)
    }
  },
  unbind: (el) => {
    (el as any).__simplLocaleDateFormatWatcher && (el as any).__simplLocaleDateFormatWatcher()
  }
} as DirectiveOptions
