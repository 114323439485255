

































































































































































  import Vue from 'vue'

  import { ACTIONS } from '@simpl/auth/store/consts'
  import UserSettingsDialog from '../../components/dialogs/user-profile-dialog/UserSettingsDialog.vue'
  import ContentDialog from '../../components/dialogs/ContentDialog.vue'
  import LegalDisclosureDialog from '../../components/dialogs/LegalDisclosureDialog.vue'
  import { appCreated } from '../../init-app'
  import { getTextForUserLanguage } from '../../utils'
  import { Status, User } from '../../types/graphql'
  import { MUTATIONS } from '../../plugins/store/consts'
  import { STATUS } from '../../graphql'
  import i18n from '../../plugins/i18n'
  import { TranslateResult } from 'vue-i18n'
  import { getLegalDisclosureContent } from '../../utils/get-legal-disclosure-content'

  export default Vue.extend({
    name: 'UserDropdown',

    components: {
      UserSettingsDialog,
      ContentDialog,
      LegalDisclosureDialog
    },

    data: () => ({
      userSettingsDialog: false,
      loadingSystemStatus: false,
      systemStatus: null! as Status,
      frontendVersion: 'n/a'
    }),

    computed: {
      show: {
        get (): boolean {
          return this.forced || this.$store.state._userDropdown
        },
        set (v: boolean) {
          this.$store.commit(MUTATIONS.SET_USER_DROPDOWN, v)
        }
      },
      forced (): boolean {
        return this.$store.state._forceUserDropdown
      },
      user (): User {
        return this.$store.state.auth.user
      },
      hideLegalLink () {
        return this.$store.state.auth.domain?.properties?.hideLegalLink
      },
      imprintContent (): string {
        return getLegalDisclosureContent('imprint')
      },
      privacyContent (): string {
        return getLegalDisclosureContent('privacy')
      },
      legalDisclosureFilename (): string {
        return process.env.VUE_APP_DSH_MODE ? `legalDisclosure${process.env.VUE_APP_DSH_MODE}` : 'legalDisclosure'
      },
      userRoleNames (): string {
        if (this.user.is_super) {
          return 'Super user'
        } else {
          return this.user.roles.map(role => getTextForUserLanguage(role)).join(',')
        }
      },
      userDomainName (): string {
        return getTextForUserLanguage(this.user.active_domain)
      },
      systemStatusContent (): string {
        if (!this.systemStatus) {
          return ''
        }

        const s = this.systemStatus
        const packages = Object.values(s.packages)
          .map((p: any) => `<li><strong>${p.name}:</strong> ${p.version}</li>`)
          .join('')

        return `<strong>Frontend version: ${this.frontendVersion}</strong><br />
<strong>${s.name} (${s.version})</strong><br />
<strong>Environment:</strong> ${s.environment}<br />
<strong>Debug:</strong> ${s.debug ? 'true' : 'false'}<br />
<br />
<ul>
  ${packages}
</ul>`
      }
    },

    methods: {
      async revokeImpersonation () {
        this.$router.replace('/users/list')

        // Force app into non-booted state to avoid duplicated execution of appCreated
        this.$store.state._booted = false
        await this.$store.dispatch(`auth/${ACTIONS.REVOKE_IMPERSONATION}`)
        this.show = false
        await appCreated(true)
      },

      async loadSystemStatus () {
        if (this.loadingSystemStatus) {
          return
        }

        this.loadingSystemStatus = true

        try {
          const result = await this.$apollo.query({
            query: STATUS
          })

          this.systemStatus = { ...result.data.status }
        } catch (_) {}

        this.frontendVersion = await fetch(process.env.BASE_URL + 'version').then(function (response) {
          return response.text()
        }).catch(function () {
          return 'n/a'
        })

        this.loadingSystemStatus = false
      },

      showUserSettings () {
        setTimeout(() => {
          this.userSettingsDialog = true
        })
      },
      resetCookieConsent () {
        this.$store.commit(MUTATIONS.SET_COOKIE_CONSENT_ACCEPTED, false)
      }
    }
  })
