// @ts-ignore
import headful from 'headful'
import Vue from 'vue'

const handler = (props: any) => headful(getPassedProps(props))

export default Vue.extend({
  name: 'Headful',
  props: Object.keys(headful.props),
  watch: {
    $props: {
      handler,
      deep: true,
      immediate: true
    }
  },

  activated () {
    handler(this.$props)
  },

  render () { return undefined! }
})

function getPassedProps (props: Record<string, any>) {
  return Object.keys(props).reduce<Record<string, any>>((passedProps, propKey) => {
    if (props[propKey] !== undefined) {
      passedProps[propKey] = props[propKey]
    }
    return passedProps
  }, {})
}
