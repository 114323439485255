import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'

export interface Breadcrumb {
  title?: string | TranslateResult,
  text?: string | TranslateResult,
  navHidden?: boolean
  to: string | undefined | null
}

const VueBreadcrumbs = Vue.extend({
  name: 'Breadcrumbs',

  data () {
    return {
      optionWatcher: [] as (() => void)[],
      registrationCheckInterval: null! as number,
      breadcrumbs: [] as Breadcrumb[]
    }
  },

  watch: {
    $route: 'checkInstanceRegistration'
  },

  created () {
    this.checkInstanceRegistration()
  },

  methods: {
    checkInstanceRegistration () {
      window.clearInterval(this.registrationCheckInterval)
      this.registrationCheckInterval = window.setInterval(() => {
        const allInstancesRegistered = this.$route.matched.map(m => m.instances.default).every(Boolean)
        if (allInstancesRegistered) {
          clearInterval(this.registrationCheckInterval)
          this.update()
        }
      }, 50)
    },
    update () {
      this.optionWatcher.forEach(w => w())

      setTimeout(() => {
        // Unwatch all previous breadcrumbs

        const breadcrumbs = []

        const { matched } = this.$route
        for (let i = 0; i < matched.length; i++) {
          const match = matched[i]
          const instance = match.instances.default

          const optionData = instance && instance.$options.breadcrumbs
          const metaData = (match.meta || {}).breadcrumbs
          if (optionData) {
            breadcrumbs.push(...(optionData.call(instance)))
            this.optionWatcher.push(this.$watch(optionData.bind(instance), this.update))
          } else if (metaData) {
            breadcrumbs.push(...(Array.isArray(metaData) ? metaData : [metaData]))
          }
        }

        this.breadcrumbs = breadcrumbs
        this.$emit('update', breadcrumbs.slice())
      }, 0)
    }
  },

  render: () => null!
})

Vue.component('Breadcrumbs', VueBreadcrumbs)

declare module 'vue/types/options' {
  interface ComponentOptions<V extends Vue> {
    breadcrumbs?(): Breadcrumb[]
  }
}
