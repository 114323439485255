











































import Vue from 'vue'
import WelcomeFooter from '../components/WelcomeFooter.vue'
import { REJECT_INVITATION } from '../graphql'
import Welcome from '../components/Welcome.vue'

export default Vue.extend({
  name: 'RejectInvitation',

  components: { Welcome, WelcomeFooter },

  props: {
    email: String,
    token: String
  },

  created () {
    this.rejectInvitation()
  },

  methods: {
    async rejectInvitation () {
      try {
        await this.$apollo.mutate({
          mutation: REJECT_INVITATION,

          variables: {
            data: {
              email: this.email,
              token: this.token
            }
          }
        })
        this.$notification.publish('bottom', {
          message: this.$t('core.message.dataDeleted'),
          type: 'success',
          color: 'success'
        })
      } catch (err) {
        this.$notification.publish('bottom', {
          message: this.$t(err.message),
          borderColor: 'error'
        })
      }
      await this.$router.push('/login')
    }
  }
})
