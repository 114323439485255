
















































































import Vue from 'vue'
import { Tour } from '../GuidedTour.vue'

export default Vue.extend({
  name: 'GuidedTourSelectDialog',

  model: {},

  props: {
    value: Boolean,
    tours: Array as () => Tour[]
  },

  data () {
    return {
      selected: undefined as any
    }
  },

  computed: {
    show: {
      get (): boolean {
        return this.value
      },
      set (v: boolean) {
        this.$emit('input', v)
      }
    }
  },

  methods: {
    select () {
      this.$emit('selected', this.selected)
      this.show = false
    },
    cancel () {
      this.$emit('cancel')
      this.show = false
    }
  }
})
