












































































import Vue from 'vue'
import { User } from '@simpl/core/types/graphql'
import ProfileSettings from './ProfileSettings.vue'
import SafetySettings from './SafetySettings.vue'
import Preferences from './Preferences.vue'
import { UPDATE_ME } from '@simpl/auth/graphql'
import { MUTATIONS } from '@simpl/auth/store/consts'
import { unsavedChanges } from '../../../utils/message'
import VueI18n from 'vue-i18n'
import TranslateResult = VueI18n.TranslateResult
import { EditableTheme } from '@simpl/theme/types'

export type UserSettingsTab = {
  text: string | TranslateResult,
  component: typeof Vue,
  model?: any
  hidden?: boolean
}

export default Vue.extend({
  name: 'UserSettingsDialog',

  components: {
    ProfileSettings,
    SafetySettings,
    Preferences
  },

  model: {},

  props: {
    value: Boolean
  },

  data () {
    return {
      loading: false,
      selectedTab: 0,

      hasChanged: false,
      passwordEdited: false,

      user: {} as User,
      password: {
        old: '',
        new: '',
        confirm: ''
      }
    }
  },

  computed: {
    show: {
      get (): boolean { return this.value },
      set (v: boolean) {
        this.$emit('input', v)
      }
    },
    tabs (): UserSettingsTab[] {
      return [{
        text: this.$t('core.global.profile'),
        component: ProfileSettings,
        model: this.user
      }, {
        text: this.$t('core.global.security'),
        component: SafetySettings,
        model: this.password
      }, {
        text: this.$t('core.global.preferences'),
        component: Preferences,
        hidden: !this.showPreferences
      }]
    },
    showPreferences (): boolean {
      return !!this.$store.state.auth.domain.properties.themes.length &&
        this.$store.state.auth.domain.properties.themes.filter((theme: EditableTheme) => theme.active).length > 1
    }
  },

  watch: {
    show (v) {
      this.user = v ? JSON.parse(JSON.stringify(this.$store.state.auth.user)) : {}

      if (!v) {
        Object.keys(this.password).forEach((key: string) => {
          (this.password as any)[key] = ''
        })

        this.selectedTab = 0
      }
    },
    user: {
      deep: true,
      handler (v, o) {
        this.hasChanged = Object.keys(o).length > 0
      }
    },
    password: {
      deep: true,
      handler () {
        this.hasChanged = true
        this.passwordEdited = true
      }
    }
  },

  methods: {
    getMutationVariables () {
      const data = {} as any
      if (this.passwordEdited) {
        data.password_old = this.password.old
        data.password_new = this.password.new
        data.password_confirmation = this.password.confirm
      }

      data.firstname = this.user.firstname
      data.lastname = this.user.lastname
      data.languagecode = this.user.languagecode

      if (this.user.username !== this.$store.state.auth.user.username) {
        data.username = this.user.username || null
      }

      return data
    },
    async send () {
      this.loading = true

      try {
        const result = await this.$apollo.mutate({
          mutation: UPDATE_ME,
          variables: { data: this.getMutationVariables() },
          context: {
            onValidationError: () => {
              // TODO: implement UI response
            }
          }
        })

        this.$store.commit(`auth/${MUTATIONS.UPDATE_ME}`, result.data.updateMe)
        this.onUpdateSuccessful()
      } catch (e) {
        console.log(e)
      }

      this.loading = false
    },
    onUpdateSuccessful () {
      this.show = false
      this.$notification.publish('bottom', {
        message: this.$t('core.message.savedChanges'),
        type: 'success',
        color: 'success'
      })
    },
    async cancel () {
      if (this.hasChanged) {
        this.show = await unsavedChanges(this)
      } else {
        this.show = false
      }
    }
  }
})
