




























































































































































































import Vue from 'vue'
import { TWO_FACTOR_ACTIVATE, TWO_FACTOR_DEACTIVATE, TWO_FACTOR_INITIALIZE } from '@simpl/auth/graphql'
import { MUTATIONS } from '@simpl/auth/store/consts'
import QRCode from 'qrcode'

export default Vue.extend({
  name: 'SafetySettings',

  model: {},

  props: {
    value: Object
  },

  data () {
    return {
      showPassword: false,
      passwordsEdited: false,

      requirements: false,

      twoFactorSwitchLoading: false,
      twoFactorActivateLoading: false,
      twoFactorUrl: null as string | null,
      twoFactorQrImage: null! as string | null,
      twoFactorChallengeCode: '',
      twoFactorAuthEnabled: this.$store.state.auth.user.tfa_enabled,

      secret: '' as string
    }
  },

  computed: {
    password (): Record<string, any> {
      return this.value
    },
    passwordValidation (): Record<string, any>[] {
      return [
        { check: this.password.new.length >= 8, text: this.$t('simplAuth.passwordValidation.minimumChars') },
        { check: /[a-z]/.test(this.password.new), text: this.$t('simplAuth.passwordValidation.lowercase') },
        { check: /[A-Z]/.test(this.password.new), text: this.$t('simplAuth.passwordValidation.uppercase') },
        { check: /[0-9]/.test(this.password.new), text: this.$t('simplAuth.passwordValidation.numbers') },
        {
          check: /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(this.password.new),
          text: this.$t('simplAuth.passwordValidation.specialChars')
        }
      ]
    },
    valid (): boolean {
      let valid = true
      this.passwordValidation.forEach(rule => {
        if (!rule.check) valid = false
      })
      return valid
    },
    compareOld (): boolean {
      return (this.password.new !== this.password.old) && (this.password.old !== '')
    },
    passwordsMatch (): boolean {
      return (this.password.new === this.password.confirm) && (this.password.new !== '')
    },
    showTwoFactorConfirmation (): boolean {
      return this.twoFactorQrImage !== null
    }
  },

  watch: {
    password: {
      deep: true,
      handler (v) {
        this.$emit('input', v)
      }
    }
  },

  methods: {
    async toggle2FA () {
      this.twoFactorSwitchLoading = true

      if (this.$store.state.auth.user.tfa_enabled) {
        if (await this.confirmDisableRequest()) {
          this.deactivateTwoFactor()
        } else {
          this.twoFactorAuthEnabled = true
          this.twoFactorSwitchLoading = false
        }
      } else {
        this.twoFactorAuthEnabled = true
        this.initialize2FA()
      }
    },
    async initialize2FA () {
      try {
        const result = await this.$apollo.query({
          query: TWO_FACTOR_INITIALIZE
        })

        this.secret = result.data.twoFactorInitialize.secret
        this.twoFactorUrl = result.data.twoFactorInitialize.url as string

        const opts = {
          errorCorrectionLevel: 'H',
          type: 'image/png',
          // quality: 0.3,
          margin: 1,
          color: {
            dark: '#000',
            light: '#FFF'
          }
        } as any

        (QRCode.toDataURL(this.twoFactorUrl, opts) as any)
          .then((url: string) => {
            this.twoFactorQrImage = url
          })
          .catch((err: Error) => {
            console.error(err)
          })
      } catch (e) {
        this.twoFactorAuthEnabled = false
        this.twoFactorSwitchLoading = false
      }
    },

    async activateTwoFactor () {
      let result = null
      this.twoFactorActivateLoading = true

      try {
        result = await this.$apollo.mutate({
          mutation: TWO_FACTOR_ACTIVATE,
          variables: {
            data: { code: this.twoFactorChallengeCode }
          }
        })
      } catch (e) {
        this.onUpdateFailed()
      }

      if (result) {
        this.$store.commit(`auth/${MUTATIONS.UPDATE_ME}`, result.data.twoFactorActivate.user)
        this.reset()
        this.$store.commit(`auth/${MUTATIONS.SET_TWO_FACTOR_TOKEN}`, result.data.twoFactorActivate.two_factor_token)
        this.twoFactorAuthEnabled = true
      }

      this.twoFactorActivateLoading = false
      this.$emit('change')
    },

    async deactivateTwoFactor () {
      const result = await this.$apollo.mutate({
        mutation: TWO_FACTOR_DEACTIVATE
      })

      if (result.data.twoFactorDeactivate) {
        this.$store.commit(`auth/${MUTATIONS.UPDATE_ME}`, result.data.twoFactorDeactivate)
        this.$store.commit(`auth/${MUTATIONS.SET_TWO_FACTOR_TOKEN}`, null)
        this.reset()
        this.twoFactorAuthEnabled = false
      }

      this.twoFactorSwitchLoading = false
      this.$emit('change')
    },

    reset () {
      this.twoFactorSwitchLoading = false

      this.twoFactorUrl = null!
      this.twoFactorQrImage = null!
      this.twoFactorChallengeCode = ''
    },

    cancelTFAActivation () {
      this.reset()
      this.twoFactorAuthEnabled = false
    },

    onUpdateFailed () {
      this.twoFactorSwitchLoading = true
      this.twoFactorAuthEnabled = false

      this.$notification.publish('bottom', {
        message: this.$t('simpl-auth.2fa-challenge-invalid'),
        type: 'error',
        color: 'error'
      })
    },

    async confirmDisableRequest () {
      return await this.$confirm({
        color: 'error',
        message: this.$t('simplAuth.twoFactor.confirmDeactivate'),
        buttons: [{
          text: this.$t('core.action.cancel'),
          type: 'outlined',
          answer: false
        }, {
          text: this.$t('core.global.ok'),
          color: 'error',
          answer: true
        }]
      })
    }
  }
})
