

































import Vue from 'vue'

export default Vue.extend({
  name: 'PrivacyConsentCheckbox',

  props: {
    value: Boolean,
    rules: Array
  },

  computed: {
    termsAccepted: {
      get (): boolean {
        return this.value
      },
      set (v: boolean) {
        this.$emit('changed', v)
      }
    }
  }
})
