import StateAuth from './types'

const state = {
  user: {},
  temporaryLanguage: null,
  token: null,
  refreshToken: null,
  lastTokenUpdate: null,
  expiresAt: undefined,
  rememberMe: false,
  lastUrl: undefined,
  impersonator: null,
  twoFactorToken: null,
  domain: null,
  lastDemoPopup: null,
  showDemoPopup: false,
  showPlansPopup: false,
  lastOpenedModule: null,
  anonUsername: ''
} as StateAuth

export default state
