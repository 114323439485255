import { onError } from '@apollo/client/link/error'
import { Observable, fromPromise } from '@apollo/client/core'
import store from '../store'
import { ACTIONS } from '@simpl/auth/store/consts'
import router from '../router'
import { app } from '../../init-app'

let isRefreshing = false
let pendingRequests: (() => void)[] = []

const resolvePendingRequests = () => {
  pendingRequests.map(callback => callback())
  pendingRequests = []
}

const logout = () => {
  const lastUrl = router.currentRoute.path
  router.replace('/logout').then(() => {
    store.dispatch(`auth/${ACTIONS.LOGOUT_BY_AUTH_ERROR}`, {
      lastUrl
    }).then(() => {
      router.replace('/login').then(() => {
      })
    })
  })
}

export const errorLink = onError((errResponse) => {
  const { graphQLErrors, operation, forward } = errResponse

  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      if (
        err.message === 'simpl-auth.not-authenticated' &&
        operation.operationName.toLowerCase() !== 'logout'
      ) {
        if (!store.state.auth.rememberMe) {
          return new Observable((observer) => {
            observer.error(new Error())
            forward(operation)

            logout()
          })
        }

        let forward$

        if (!isRefreshing) {
          isRefreshing = true

          forward$ = fromPromise(
            store.dispatch(`auth/${ACTIONS.REFRESH_AUTH}`)
              .then(() => {
                resolvePendingRequests()
              })
              .catch(() => {
                pendingRequests = []
                logout()
                // Handle token refresh errors e.g clear stored tokens, redirect to login, ...
              })
              .finally(() => {
                isRefreshing = false
              })
          ).filter(Boolean)
        } else {
          forward$ = fromPromise(
            new Promise<void>(resolve => {
              pendingRequests.push(() => resolve())
            })
          )
        }

        return forward$.flatMap(() => forward(operation))
      }

      if (err.message === 'simpl-core.validation-failed') {
        const validationErrorCb = operation.getContext().onValidationError || ((_: any) => {})
        const rawValidationErrors = (err as any).extensions.validation
        const validationErrors = parseValidationError(rawValidationErrors, operation.variables)
        app.$notification.publish('bottom', {
          message: validationErrors.join('\n'),
          borderColor: 'error'
        })
        validationErrorCb(rawValidationErrors)
      }

      if (err.message.substr(0, 33) === 'simpl-access-control.redirect-to:' && router.currentRoute.name !== 'base-management.event.view') {
        setTimeout(() => {
          window.location.href = err.message.substr(33)
        }, 1500)
      }
    }
  }

  forward(operation)
})

export function parseValidationError (validationErrors: Record<string, string[]>, variables: Record<string, any>) {
  const retVal: string[] = []

  for (const attrKey in validationErrors) {
    const attrErrors = validationErrors[attrKey]
    const attrName = sanitizeAttrName(attrKey)
    const attrTranslation = app.$te(`validation.attributes.${attrName}`)
      ? app.$t(`validation.attributes.${attrName}`)
      : `${attrName.charAt(0).toUpperCase()}${attrName.slice(1)}`
    for (const validationError of attrErrors) {
      const [key, value] = validationError.split(':')
      const tData: Record<string, any> = { attribute: attrTranslation }
      if (typeof value !== 'undefined') {
        tData[key] = value
      }

      if (app.$te(`validation.${key}`)) {
        const transValue = app.$t(`validation.${key}`)
        if (typeof transValue === 'string') {
          retVal.push(app.$t(`validation.${key}`, tData) as any)
        } else {
          retVal.push(app.$t(`validation.${key}.${getTypeForAttribute(attrName, variables)}`, tData) as any)
        }
      }
    }
  }

  if (!retVal.length) {
    retVal.push(app.$t('validation.unknown_error') as string)
  }

  return retVal
}

function sanitizeAttrName (attribute: string) {
  return attribute.replace('data.', '')
}

function getTypeForAttribute (key: string, variables: Record<string, any>) {
  const value = variables[key]

  if (typeof value === 'number') {
    return 'numeric'
  }
  if (Array.isArray(value)) {
    return 'array'
  }
  return 'string'
}
