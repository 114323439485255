






















































































































import mixins from 'vue-typed-mixins'
import UserDropdown from '@simpl/core/components/toolbar/UserDropdown.vue'
import LanguageDropdown from './toolbar/LanguageDropdown.vue'
import SetAnonUsernameDialog from './dialogs/SetAnonUsernameDialog.vue'
import { Breadcrumb } from '@simpl/core/plugins/breadcrumbs'
import MobileDetection from '../mixins/utils/MobileDetection'

export default mixins(MobileDetection).extend({
  name: 'AppBar',

  components: { UserDropdown, LanguageDropdown, SetAnonUsernameDialog },

  props: {
    loggedIn: Boolean
  },

  data () {
    return {
      crumbs: [] as Breadcrumb[],
      navHidden: false,
      badge: process.env.VUE_APP_ENV_BADGE || '',
      showSetAnonUsernameDialog: false
    }
  },

  computed: {
    pageTitle (): string {
      return `${this.crumbs.map(c => c.text ? this.$t(c.text as string) : c.title).join(' / ')}`
    }
  },

  watch: {
    pageTitle: {
      handler (v) {
        this.$emit('update-page-title', v)
      },
      immediate: true
    }
  },

  updated () {
    let navHidden = false

    for (let i = 0; i < this.crumbs.length; i++) {
      if (Object.prototype.hasOwnProperty.call(this.crumbs[i], 'navHidden')) {
        navHidden = !!this.crumbs[i].navHidden
      }
    }
    this.navHidden = navHidden
  },

  methods: {
    async navigateTop () {
      if (this.crumbs.length < 2) {
        if (this.$router.currentRoute.path === '/' || !this.loggedIn) return

        await this.$router.push('/')
      } else {
        let target = null
        for (let i = 0; i < this.crumbs.length; i++) {
          if (this.crumbs[i].to) {
            target = this.crumbs[i].to
          }
        }

        if (!target && !this.loggedIn) return
        await this.$router.push(target || '/')
      }
    },
    showPlans () {
      this.$emit('show-plans')
    }
  }
})
