



























































import Vue from 'vue'
import debounce from 'lodash.debounce'
import { convertToUnit } from '@simpl/core/utils/core'

export default Vue.extend({
  name: 'CoreSearchFilterBar',

  props: {
    viewSelection: Boolean,
    view: {
      type: String,
      default: 'list'
    },
    views: {
      type: Object,
      default: () => ({
        list: 'mdi-view-headline',
        tiles: 'mdi-apps'
      })
    },
    search: Boolean,
    searchValue: {
      type: String,
      default: ''
    },
    searchWidth: {
      type: [Number, String],
      default: 200
    },
    searchDelay: {
      type: [Number, String],
      default: 500
    },
    filters: Array,
    left: Boolean
  },

  data () {
    return {
      internalSearchValue: this.searchValue,
      emitSearch: null! as any
    }
  },

  computed: {
    computedSearchWidth (): string | undefined {
      return convertToUnit(this.searchWidth)
    }
  },

  watch: {
    internalSearchValue () {
      this.emitSearch()
    },
    searchValue (v) {
      this.internalSearchValue = v
    }
  },

  created () {
    this.emitSearch = debounce(function (this: any) {
      this.$emit('update:search-value', this.internalSearchValue)
    }, this.searchDelay as any)
  }
})
