import Vue, { Component } from 'vue'

export interface AppModuleDashboard {
  title?: string
  description?: string,
  icon: string,
  link: string,
  imageSrc?: string,
  color?: string,
  permission?: string | (() => boolean),
  order?: number,
  isSuper?: boolean
}

interface IAppModule {
  key: string
  meta?: Record<string, any>
  dashboard?: AppModuleDashboard
  widget?: Component
  unregister?: () => void
}

export interface IAppModuleThemable {
  key: string
  title: string
  description?: string
  icon: string
  imageSrc?: string
  color?: string
  order?: number
}

class AppModules {
  private _modules: Record<string, IAppModule> = {}
  private _themable: Record<string, IAppModuleThemable> = {}

  constructor () {
    Vue.set(this, '_modules', {})
  }

  add (module: IAppModule) {
    if (this._modules[module.key]) return

    Vue.set(this._modules, module.key, module)
  }

  themable (themable: IAppModuleThemable) {
    Vue.set(this._themable, themable.key, themable)
  }

  delete (key: string) {
    Vue.delete(this._modules, key)
  }

  all () {
    return this._modules
  }

  themables () {
    return Object.values(this._themable)
  }

  clear () {
    for (const key in this._modules) {
      this.delete(key)
    }

    for (const key in this._themable) {
      Vue.delete(this._themable, key)
    }
  }
}

Vue.use(() => {
  Vue.prototype.$appModules = new AppModules()
})

declare module 'vue/types/vue' {
  interface Vue {
    // @ts-ignore
    $appModules: AppModules
  }
}
