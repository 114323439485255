





















import Vue from 'vue'
import { PasswordRules } from '../mixins/PasswordValidator'

export default Vue.extend({
  name: 'PasswordHints',

  props: {
    passwordValidation: Array as () => PasswordRules
  }
})
