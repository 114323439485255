import Vue from 'vue'
import NotificationCenterComponent from './NotificationCenter'

interface INotificationCenter {
  publish (target: string, data: Record<string, any>): void
  subscribe (target: string, callback: (data: Record<string, any>) => void): void
}

class NotificationCenter implements INotificationCenter {
  private _notificationCenter: Vue = new Vue({})

  publish (target: string, data: Record<string, any>) {
    this._notificationCenter.$emit(target, data)
  }

  subscribe (target: string, callback: (data: Record<string, any>) => void) {
    this._notificationCenter.$on(target, callback)
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    readonly $notification: INotificationCenter
  }
}

Vue.component('NotificationCenter', NotificationCenterComponent)
Vue.prototype.$notification = new NotificationCenter()
