import Vue, { VNode } from 'vue'
import { DirectiveBinding } from 'vue/types/options'

interface VuePermissionOptions {
  strict: boolean
}

type VuePermissionValidator = (...params: any[]) => boolean

const valid: VuePermissionValidator = () => true
const DEFAULT_OPTIONS: VuePermissionOptions = {
  strict: false
}

interface IVuePermission {
  setRoles (roles: string | string[]): void

  set (type: string, ability: string, validator: () => boolean): void
  remove (type: string, ability: string): void

  is (role: string): boolean
  can (type: string | undefined | null, ability: string, ...params: any[]): boolean
  some (type: string | undefined | null, abilities: string[], ...params: any[]): boolean

  every (type: string | undefined | null, abilities: string[], ...params: any[]): boolean

  setUser (user: Record<string, any>): void

  reset (): void
}

export class VuePermission implements IVuePermission {
  private options: VuePermissionOptions
  private _roles: string[] = []
  private _rules: Record<string, Record<string, VuePermissionValidator>> = {}
  private $user: Record<string, any> = {}

  constructor (options: VuePermissionOptions = DEFAULT_OPTIONS) {
    /** @private */
    this.options = { ...DEFAULT_OPTIONS, ...(options || {}) }
    this.reset()
  }

  setRoles (roles: string | string[]) {
    if (!roles) return true

    roles = Array.isArray(roles) ? roles : [roles]
    roles.forEach(r => {
      const role = r.toLowerCase()
      if (!this._roles.includes(role)) {
        this._roles.push(role)
      }
    })
  }

  set (type: string, ability: string, validator: () => boolean = valid) {
    const typeAbilities = this._rules[type] || (this._rules[type] = {})
    typeAbilities[ability] = validator
  }

  remove (type: string, ability: string) {
    const typeAbilities = this._rules[type] || (this._rules[type] = {})
    delete typeAbilities[ability]

    if (!Object.keys(typeAbilities).length) delete this._rules[type]
  }

  is (role: string) {
    return this._roles.includes(role.toLowerCase())
  }

  can (type: string | undefined | null, ability: string, ...params: any[]): boolean {
    if (!ability) return true

    if (this.options.strict && !type) {
      console.warn('[@vue/permission] Type has to be defined in strict mode')
      return false
    }

    const rules = type
      ? this._rules[type]
      : Object.keys(this._rules).reduce((acc, key) => {
        return { ...acc, ...this._rules[key] }
      }, {})

    return rules && rules[ability] && rules[ability].call(null, this.$user, ...params)
  }

  some (type: string | undefined | null, abilities: string[], ...params: any[]): boolean {
    if (this.options.strict && !type) {
      console.warn('[@vue/permission] Type has to be defined in strict mode')
      return false
    }

    return abilities.map(ability => this.can(type, ability, ...params)).some(Boolean)
  }

  every (type: string | undefined | null, abilities: string[], ...params: any[]): boolean {
    if (this.options.strict && !type) {
      console.warn('[@vue/permission] Type has to be defined in strict mode')
      return false
    }

    return abilities.map(ability => this.can(type, ability, ...params)).every(Boolean)
  }

  setUser (user: Record<string, any>) {
    this.$user = user
  }

  reset () {
    this._rules = {}
    this._roles = []
    this.$user = {}
  }
}

declare module 'vue/types/vue' {
  export interface Vue {
    $permission: IVuePermission
  }
}

Vue.prototype.$permission = new VuePermission()

const check = function (el: Element, binding: DirectiveBinding, vNode: VNode) {
  if (el) {
    const permissionCtx: any = vNode.context?.$permission
    const value = permissionCtx[binding.name](binding.arg, binding.value)
    if (binding.modifiers.disable) {
      value ? el.removeAttribute('disabled') : el.setAttribute('disabled', 'disabled')
    } else if (binding.modifiers.readonly) {
      value ? el.removeAttribute('readonly') : el.setAttribute('readonly', 'readonly')
    } else {
      if (!value) {
        commentNode(el, vNode)
      }
    }
  }
}

const checkIs = function (el: Element, binding: DirectiveBinding, vNode: VNode) {
  if (el) {
    const value = vNode.context?.$permission.is(binding.value)
    if (!value) {
      commentNode(el, vNode)
    }
  }
}

const can = { bind: check, update: check }
const some = { bind: check, update: check }
const every = { bind: check, update: check }
const is = { bind: checkIs, update: checkIs }

function commentNode (el: Element, vNode: VNode) {
  const comment = document.createComment(' ')

  Object.defineProperty(comment, 'setAttribute', {
    value: () => undefined
  })

  vNode.text = ' '
  vNode.elm = comment
  vNode.isComment = true
  vNode.tag = undefined
  vNode.data!.directives = undefined

  if (vNode.componentInstance) {
    (vNode.componentInstance as any).$el = comment
  }

  if (el.parentNode) {
    el.parentNode.replaceChild(comment, el)
  }
}

Vue.directive('can', can)
Vue.directive('some', some)
Vue.directive('every', every)
Vue.directive('is', is)
