
























































import mixins from 'vue-typed-mixins'
import { LearningPath, RunDashboard } from '@simpl/core/types/graphql'
import RunDescription from '../../components/RunDescription.vue'
import RunTypes from '../../runs/mixins/RunTypes'

export type RunDashboardExtended = RunDashboard & {
  completed: boolean,
  attending: boolean,
  locked: boolean
}

export default mixins(RunTypes).extend({
  name: 'LearningPathStepper',

  components: { RunDescription },

  props: {
    learningPath: Object as () => LearningPath,
    runDashboards: Array as () => RunDashboard[],
    consecutive: Boolean
  },

  data () {
    return {
      selectedStep: -1
    }
  },

  computed: {
    runs (): RunDashboardExtended[] {
      const orderedRuns = this.learningPath?.properties?.runAgenda
        ?.map((id: string) => this.runDashboards.find(runDashboard => runDashboard.run!.id === id)) ||
        this.runDashboards

      return orderedRuns.map((rd: RunDashboard) => ({
        ...rd,
        completed: this.isCompleted(rd),
        attending: this.isAttending(rd),
        locked: this.consecutive && !this.isPreviousCompleted(orderedRuns, rd.run!.id) && !this.isCompleted(rd)
      }))
    }
  },

  watch: {
    learningPath () {
      this.selectedStep = this.runs.findIndex((rd: RunDashboardExtended) => !rd.completed && !rd.locked)
    }
  },

  methods: {
    isAttending (runDashboard: RunDashboard) {
      return runDashboard.configuration!.user!.status === 'attending'
    },
    isCompleted (runDashboard: RunDashboard) {
      return runDashboard.configuration!.user!.status === 'completed'
    },
    isPreviousCompleted (runs: RunDashboard[], id: string): boolean {
      const indexOfPrevious = runs.findIndex(rd => rd.run!.id === id) - 1
      if (indexOfPrevious < 0) return true

      return this.isCompleted(runs[indexOfPrevious])
    }
  }
})
