import Vue from 'vue'
import VueAxios from 'vue-axios'
// eslint-disable-next-line import/no-named-default
import { default as axiosBase } from 'axios'
import store from '../store'

export const axios = axiosBase.create({
  baseURL: process.env.VUE_APP_GRAPHQL_HTTP_ENDPOINT
})

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  const token = store.state.auth.token

  if (token) {
    const headers = config.headers || (config.headers = {})
    headers.authorization = `Bearer ${token}`
  }

  const twoFactorToken = store.state.auth.twoFactorToken
  if (twoFactorToken) {
    const headers = config.headers || (config.headers = {})
    headers['two-factor-token'] = twoFactorToken
  }

  return config
}, function (error) {
  return Promise.reject(error)
})

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  // TODO: Handle 503 (Maintenance Mode) error
  return Promise.reject(error)
})

Vue.use(VueAxios, axios)
