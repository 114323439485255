<template>
  <div class="login-view d-flex fill-height flex-column-reverse flex-md-row">
    <v-sheet
      v-if="$vuetify.breakpoint.smAndDown"
      color="transparent"
    >
      <WelcomeFooter />
    </v-sheet>
    <v-slide-x-transition>
      <LoginForm
        :user-id="userId"
        :token="token"
        :email="email"
      >
        <template #logo>
          <v-img
            v-if="$vuetify.breakpoint.mdAndUp"
            class="logo"
            :src="$domainImages.logo ? $domainImages.logo : require('@/assets/logo-large.png')"
            width="100%"
          />
        </template>
      </LoginForm>
    </v-slide-x-transition>
    <Welcome>
      <template #footer>
        <WelcomeFooter v-if="$vuetify.breakpoint.mdAndUp" />
      </template>
    </Welcome>
    <v-sheet
      v-if="$vuetify.breakpoint.smAndDown"
      class="pa-8"
      tile
    >
      <v-img
        class="mx-auto"
        :src="$domainImages.logo ? $domainImages.logo : require('@/assets/logo-large.png')"
        width="100%"
        max-width="300"
      />
    </v-sheet>
  </div>
</template>

<script>
  import LoginForm from '../components/LoginForm'
  import Welcome from '@simpl/auth/components/Welcome'
  import WelcomeFooter from '@simpl/auth/components/WelcomeFooter'

  export default {
    name: 'LoginView',

    components: {
      Welcome,
      LoginForm,
      WelcomeFooter
    },

    props: {
      userId: String,
      token: String,
      email: String
    }
  }
</script>

<style lang="scss">
  .login-view {
    background: no-repeat center center;
    background-size: cover;

    body.simpl & {
      background-image: url('~@simpl/core/assets/global/background.png')
    }
  }
</style>
