<template>
  <div class="fill-height d-flex align-center justify-center flex-column">
    <v-icon
      size="200"
      class="mb-6"
    >
      mdi-emoticon-sad-outline
    </v-icon>
    <span class="headline mb-12">{{ $t('error.404') }}</span>
    <v-btn
      to="/"
      replace
      outlined
    >
      Zurück zur Startseite
    </v-btn>
  </div>
</template>

<script>
  export default {
    name: 'Error404'
  }
</script>

<style scoped>

</style>
