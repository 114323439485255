import { Observer, ApolloLink, FetchResult, Observable, Operation } from '@apollo/client/core'
import pusher from '../pusher'

class PusherLink extends ApolloLink {
  request (operation: Operation, forward: (operation: Operation) => Observable<FetchResult>): Observable<any> {
    return new Observable(observer => {
      // Check the result of the operation
      forward(operation).subscribe({
        next: data => {
          // If the operation has the subscription extension, it's a subscription
          const subscriptionChannel = this._getChannel(
            data,
            operation
          )

          if (subscriptionChannel) {
            this._createSubscription(subscriptionChannel, observer)
          } else {
            // No subscription found in the response, pipe data through
            observer.next(data)
            observer.complete()
          }
        }
      })
    })
  }

  _getChannel (data: Record<string, any> & { extensions?: any; context?: any }, operation: Operation) {
    return !!data.extensions &&
    !!data.extensions.lighthouse_subscriptions &&
    !!data.extensions.lighthouse_subscriptions.channels
      ? data.extensions.lighthouse_subscriptions.channels[
        operation.operationName
      ]
      : null
  }

  _createSubscription (subscriptionChannel: string, observer: Observer<any>) {
    const pusherChannel = pusher.subscribe(subscriptionChannel)
    ;(observer as any)._subscription._cleanup = () => {
      pusher.unsubscribe(subscriptionChannel)
      observer.complete!()
    }
    // Subscribe for more update
    pusherChannel.bind('lighthouse-subscription', (payload: any) => {
      if (!payload.more) {
        // This is the end, the server says to unsubscribe
        pusher.unsubscribe(subscriptionChannel)
        observer.complete!()
      }
      const result = payload.result
      if (result) {
        // Send the new response to listeners
        observer.next!(result)
      }
    })
  }
}

export default PusherLink
