import { VueConfirmOptions } from './types'

export const confirmInstances: Record<string, any> = {}

export default function confirm (options: VueConfirmOptions) {
  const name = options.name || 'default'
  const instance = confirmInstances[name]

  if (!instance) {
    console.warn('[HawkeyeConfirm] No confirm instance for name ' + name)
    return
  }

  return new Promise(resolve => {
    // render dialog
    instance.data = options
    instance.show(resolve)
  })
}
