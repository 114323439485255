import { DirectiveOptions } from 'vue'
import { getTextForUserLanguage, getTextForTemporaryUserLanguage } from '../utils'

export default {
  bind: (el, binding, vNode) => {
    const key = binding.arg || 'display_name'

    const targetProperty = binding.modifiers.html ? 'innerHTML' : 'innerText'

    if (vNode.context) {
      (el as any).__simplTextWatcher = vNode.context?.$watch(() => {
        return binding.value
      }, (v) => {
        el[targetProperty] = binding.modifiers.temporary
          ? getTextForTemporaryUserLanguage(v, key)
          : getTextForUserLanguage(v, key)
      }, {
        deep: true,
        immediate: true
      })
    } else {
      el[targetProperty] = binding.modifiers.temporary
        ? getTextForTemporaryUserLanguage(binding.value, key)
        : getTextForUserLanguage(binding.value, key)
    }
  },
  unbind: (el) => {
    (el as any).__simplTextWatcher && (el as any).__simplTextWatcher()
  }
} as DirectiveOptions
