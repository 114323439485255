import Vue from 'vue'

export default Vue.extend({
  data: () => ({
    types: [
      'course', 'event', 'survey', 'assessment', 'workshop', 'training', 'presentation'
    ] as string[]
  }),

  methods: {
    typesByMode (mode: string): object[] {
      if (mode === 'course') {
        return [
          { id: 'course', name: this.$t('run.types.course') }
        ]
      } else {
        const types = [] as object[]
        this.types.forEach((type: string) => {
          types.push({
            id: type,
            name: this.$t(`run.types.${type}`)
          })
        })
        return types
      }
    },
    iconByType (type: string): string {
      switch (type) {
        case 'event':
          return 'mdi-calendar-check'
        case 'survey':
          return 'mdi-chart-line'
        case 'assessment':
          return 'mdi-certificate'
        case 'workshop':
          return 'mdi-school'
        case 'training':
          return 'mdi-presentation'
        case 'presentation':
          return 'mdi-television-play'
        case 'course':
          return 'mdi-school'
        default:
          return 'mdi-calendar'
      }
    }
  }
})
