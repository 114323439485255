export const ACTIONS = {
  LOGIN: 'performLogin',
  AUTO_LOGIN: 'autoLogin',
  REFRESH_AUTH: 'refreshAuth',
  RESET_PASSWORD: 'resetPassword',
  ACCEPT_INVITATION: 'acceptInvitation',
  UPDATE_ME: 'updateMe',
  LOGOUT: 'performLogout',
  LOGOUT_BY_AUTH_ERROR: 'performLogoutByAuthError',
  IMPERSONATE: 'impersonate',
  REVOKE_IMPERSONATION: 'revokeImpersonation',
  PERFORM_TWO_FACTOR_CHALLENGE: 'performTwoFactorChallenge',
  CHECK_DOMAIN_STATUS: 'checkDomainStatus'
}

export const MUTATIONS = {
  SET_AUTH_PAYLOAD: 'setAuthPayload',
  IMPERSONATE: 'impersonate',
  UPDATE_ME: 'updateMe',
  REMEMBER: 'remember',
  LOGOUT: 'logout',
  SET_USER_LANGUAGE: 'setUserLanguage',
  SET_TEMPORARY_LANGUAGE: 'setTemporaryLanguage',
  SET_LAST_URL: 'setLastUrl',
  REVOKE_IMPERSONATION: 'revokeImpersonation',
  SET_TWO_FACTOR_TOKEN: 'setTwoFactorToken',
  SET_DOMAIN: 'setDomain',
  SET_LAST_DEMO_POPUP: 'setLastDemoPopup',
  SET_SHOW_DEMO_POPUP: 'setShowDemoPopup',
  SET_SHOW_PLANS_POPUP: 'setShowPlansPopup',
  SET_LAST_OPENED_MODULE: 'setLastOpenedModule',
  SET_ANON_USERNAME: 'setAnonUsername'
}
