import type Vue from 'vue'

interface RequireContext {
  keys(): string[];
  (id: string): any;
  <T>(id: string): T;
  resolve(id: string): string;
  /** The module id of the context module. This may be useful for module.hot.accept. */
  id: string;
}

function _loadLocalizations (contextCreator: () => RequireContext) {
  const context = contextCreator()
  const regExPath = /\.\/([a-zA-Z-]+)\/([a-zA-Z-/]+)\.(json|ts)/

  const localizations: Record<string, any> = {}

  for (const file of context.keys()) {
    const content = context(file)
    const match = regExPath.exec(file)!

    if (!match) continue

    const paths = [match[1], ...match[2].split(/\//)]
    let target = localizations

    for (let i = 0; i < paths.length - 1; i++) {
      const path = paths[i]
      if (!target[path]) {
        target[path] = {}
      }
      target = target[path]
    }

    target[paths[paths.length - 1]] = content?.default ? content.default : content
  }

  return { context, localizations }
}

export async function loadLocalizations (
  app: Vue,
  contextCreator: () => RequireContext,
  modifier?: (data: Record<string, any>) => Promise<void>
) {
  const { context, localizations } = _loadLocalizations(contextCreator)

  if (modifier) {
    await modifier(localizations)
  }

  Object.keys(localizations)
    .forEach((locale) => _mergeLocale(app, locale, localizations))

  if (module.hot) {
    module.hot.accept(context.id, () => {
      const { localizations: newLocalizations } = _loadLocalizations(contextCreator)

      Object.keys(newLocalizations)
        .forEach((locale) => _mergeLocale(app, locale, newLocalizations))
    })
  }
}

function _mergeLocale (app: Vue, locale: string, localizations: Record<string, any>) {
  const newContent = localizations[locale]
  const dateTime = newContent.dateTimeFormats

  if (dateTime) {
    const oldFormat = app.$i18n.getDateTimeFormat(locale)
    app.$i18n.setDateTimeFormat(locale, { ...oldFormat, ...dateTime })
    delete newContent.dateTimeFormats
  }

  app.$i18n.mergeLocaleMessage(locale, newContent)
}
