import Vue from 'vue'
import { hasOwn, debounce } from '../../utils'

let userSettingsQueue: Record<string, any> = {}

export default function (identifier: string, keys: string[], watch: boolean = true) {
  return Vue.extend({
    data () {
      return {
        userSettingsIdentifier: null! as string,

        // eslint-disable-next-line vue/no-reserved-keys
        __userSettingsWatcher: [] as (() => void)[]
      }
    },

    created () {
      const storedValues = this.$store.getters['auth/settings'](this.userSettingsIdentifier || identifier)

      keys.forEach(key => {
        if (!hasOwn(storedValues, key)) return

        (this as any)[key] = storedValues[key]
      })

      if (watch) {
        this.__userSettingsWatcher = keys.map(key => this.$watch(key, this.updateUserSettings))
      }
    },

    methods: {
      updateUserSettings () {
        const values = keys.reduce<Record<string, any>>((acc, key) => {
          acc[key] = (this as any)[key]
          return acc
        }, {})

        userSettingsQueue[this.userSettingsIdentifier || identifier] = JSON.parse(JSON.stringify(values))
        if (!this.$store.state.auth.impersonator) {
          this.scheduleUpdate()
        }
      },

      scheduleUpdate: debounce(async function (this: Vue) {
        await this.$store.dispatch('auth/updateMe', {
          settings: {
            create_or_update: JSON.stringify(userSettingsQueue)
          }
        })
        userSettingsQueue = {}
      }, 100)
    }
  })
}
