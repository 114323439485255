




































































import Vue from 'vue'
import GuidedTourSelectDialog from './dialogs/GuidedTourSelectDialog.vue'
import UserSettings from '../mixins/utils/UserSettings'

interface TourStepCommit {
  mutation: string,
  value?: any,
}

export interface TourStep {
  pre?: () => Promise<void>
  post?: () => Promise<void>
  selector: string | null
  headline?: string
  text: string
  position: 'top' | 'right' | 'bottom' | 'left' | 'center',
  width?: number | string
}

export interface Tour {
  name?: string
  description?: string
  icon?: string
  steps: TourStep[]
}

export default Vue.extend({
  name: 'GuidedTour',

  components: {
    GuidedTourSelectDialog
  },

  mixins: [
    UserSettings('guidedTour', [
      'tourFinished'
    ], false)
  ],

  props: {
    id: {
      type: String,
      required: true
    },
    tours: {
      type: Array as () => Tour[],
      required: true
    },
    forceShow: {
      type: Boolean,
      default: false
    }
  },

  data () {
    const userSettingsIdentifier = `${this.id}GuidedTour`

    return {
      userSettingsIdentifier,
      show: this.forceShow || !this.$store.getters['auth/settings'](userSettingsIdentifier)?.tourFinished,
      currentStepIndex: 0,
      highlightStyle: null! as Record<string, any>,
      selectedTourIndex: 0,
      showDialog: false,
      tourFinished: false,
      loading: false
    }
  },

  computed: {
    selectedTour (): Tour {
      return this.tours[this.selectedTourIndex]
    },
    currentStep (): TourStep {
      return this.selectedTour.steps[this.currentStepIndex]
    },
    disableNext (): boolean {
      return this.currentStepIndex + 1 === this.selectedTour.steps.length
    }
  },

  watch: {
    show (v) {
      this.$emit(v ? 'show' : 'close')
    },
    async currentStep (v: TourStep | null, o: TourStep | null) {
      if (o?.post) {
        this.loading = true
        await o.post()
      }

      if (v?.pre) {
        this.loading = true
        await v.pre()
      }

      if (v) {
        this.setHighlightStyle()
      }

      this.loading = false
    }
  },

  mounted () {
    this.setHighlightStyle()

    this.showDialog = this.tours.length > 1
  },

  methods: {
    setHighlightStyle () {
      if (this.currentStep.selector === null) {
        this.highlightStyle = {
          top: '50%',
          left: '50%',
          height: 0,
          width: 0
        }
        return
      }

      const targetEl = document.querySelector(this.currentStep.selector) as HTMLElement

      if (!targetEl) {
        this.currentStepIndex++
        return
      }

      const targetELRect = targetEl.getBoundingClientRect() as DOMRect

      this.highlightStyle = {
        top: `${targetELRect.top}px`,
        left: `${targetELRect.left}px`,
        height: `${targetELRect.height}px`,
        width: `${targetELRect.width}px`
      }
    },
    async endTour () {
      this.show = false
      this.tourFinished = true;

      (this as any).updateUserSettings()
    }
  }
})
