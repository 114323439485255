

























































import Vue from 'vue'
import { ParticipantDashboardLearningPath } from '@simpl/core/types/graphql'

export default Vue.extend({
  name: 'ProgressOverview',

  props: {
    learningPaths: Array as () => ParticipantDashboardLearningPath[]
  },

  computed: {
    allCoursesCount (): number {
      let count = 0
      this.learningPaths.forEach(lp => {
        count += lp.run_dashboards.length
      })

      return count
    },
    attendingCoursesCount (): number {
      let attendingCount = 0

      this.learningPaths.forEach(lp => {
        lp.run_dashboards.forEach(rd => {
          if (rd.configuration!.user.status === 'attending') attendingCount += 1
        })
      })

      return attendingCount
    },
    attendingCoursesPercentage (): string {
      return `${(this.attendingCoursesCount / this.allCoursesCount) * 100}%`
    },
    completedCoursesCount (): number {
      let completedCount = 0

      this.learningPaths.forEach(lp => {
        lp.run_dashboards.forEach(rd => {
          if (rd.configuration!.user.status === 'completed') completedCount += 1
        })
      })

      return completedCount
    },
    completedCoursesPercentage (): string {
      return `${(this.completedCoursesCount / this.allCoursesCount) * 100}%`
    }
  }
})
