import { GetterTree } from 'vuex'
import StateAuth from './types'
import { Permission, Role } from '@simpl/core/types/graphql'

export default {
  roles: state => {
    return state.user.roles || []
  },
  permissions: (state, getters) => {
    return getters.roles.reduce((acc: any[], r: Role) => {
      return acc.concat(r.permissions)
    }, []) as Permission[]
  },
  features: (state, getters) => {
    return getters.permissions.reduce((acc: any[], p: Permission) => {
      const { identifier } = p.feature!
      if (!acc.includes(identifier)) acc.push(identifier)
      return acc
    }, [])
  },
  settings: (state) => (id: string) => {
    const settingsObj = state.user.settings && state.user.settings[id]
    return settingsObj ? settingsObj.value : {}
  }
} as GetterTree<StateAuth, any>
