import Vue from 'vue'

interface AppConfig {
  maxStringLength: string
}

const config = Object.freeze({
  maxStringLength: '255'
}) as AppConfig

Vue.use(() => {
  Vue.prototype.$config = config
})

declare module 'vue/types/vue' {
  interface Vue {
    $config: AppConfig
  }
}
