































































































































import Vue from 'vue'
import { Tag, User, UserExists } from '../../../types/graphql'
import { getTextForUserLanguage } from '../../../utils'
import { OPT_OUT } from '@simpl/auth/graphql'
import { ACTIONS } from '@simpl/auth/store/consts'
import { USER_EXISTS } from '@simpl/access-control/graphql'
import { TranslateResult } from 'vue-i18n'

export default Vue.extend({
  name: 'ProfileSettings',

  model: {},

  props: {
    value: Object as () => User
  },

  data () {
    return {
      rules: {
        required: (value: string) => !!value || this.$t('core.global.required'),
        minLength: (v: string) => {
          return !v?.length || v.length >= 6 || this.$t('simplAuth.auth.usernameMinLength')
        }
      },
      userExists: null! as UserExists,
      errorMessageEmailExists: '' as string | TranslateResult,
      errorMessageUsernameExists: '' as string | TranslateResult
    }
  },

  computed: {
    user (): User {
      return this.value || {}
    },
    languages (): string[] {
      const languages = this.user.tags?.filter((t: Tag) => t.category!.identifier === 'language')
        .map((t: any) => {
          t.name = getTextForUserLanguage(t, 'native_name')
          return t
        })

      return languages?.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0) || []
    },
    canSelfOptOut (): boolean {
      return !!this.$store.state.auth.user?.active_domain?.properties?.self_opt_out
    },
    userExistsInOtherDomain (): boolean {
      return this.userExists?.exists && this.userExists.status === 'other-domain'
    }
  },

  watch: {
    user: {
      deep: true,
      handler (v) {
        this.$emit('input', v)
      }
    }
  },

  methods: {
    getTextForUserLanguage,

    async emailExists (): Promise<void> {
      if (!this.user.email || this.user.email === this.$store.state.auth.user.email) return

      const exists = await this.$apollo.query({
        query: USER_EXISTS,
        variables: {
          type: 'email',
          query: this.user.email
        }
      })

      this.userExists = exists.data.userExists

      if (this.userExistsInOtherDomain) {
        const user = this.userExists?.user as any

        Object.keys(this.user).forEach(key => {
          (this.user as any)[key] = user[key]
        })
      }

      if (this.userExists?.exists) {
        const statusMsg = this.userExists.status!.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
          return g1.toUpperCase() + g2.toLowerCase()
        }).replace('-', '')
        this.errorMessageEmailExists = this.$t(`user.error.alreadyExists${statusMsg}`)
      }
    },

    async usernameExists (): Promise<void> {
      if (!this.user.username || this.user.username === this.$store.state.auth.user.username) return

      const exists = await this.$apollo.query({
        query: USER_EXISTS,
        variables: {
          type: 'username',
          query: this.user.username
        }
      })

      this.userExists = exists.data.userExists

      if (this.userExistsInOtherDomain) {
        const user = this.userExists?.user as any

        Object.keys(this.user).forEach(key => {
          (this.user as any)[key] = user[key]
        })
      }

      if (this.userExists?.exists) {
        const statusMsg = this.userExists.status!.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
          return g1.toUpperCase() + g2.toLowerCase()
        }).replace('-', '')
        this.errorMessageUsernameExists = this.$t(`user.error.alreadyExists${statusMsg}`)
      }
    },

    async optOut () {
      const confirmDelete = await this.$confirm({
        x: 'right',
        y: 'top',
        message: this.$t('core.message.deleteConfirm', [this.$t('core.global.profile')]),
        buttons: [{
          text: this.$t('core.action.cancel'),
          type: 'outlined',
          answer: false
        }, {
          text: this.$t('core.global.yes'),
          uppercase: true,
          color: 'primary',
          answer: true
        }]
      })

      if (confirmDelete) {
        try {
          await this.$apollo.mutate({
            mutation: OPT_OUT,
            variables: {
              data: { type: 'deletion' }
            }
          })
          this.$notification.publish('bottom', {
            message: this.$t('core.message.dataDeleted'),
            type: 'success',
            color: 'success'
          })
          await this.$router.push('/login')
          await this.$store.dispatch(`auth/${ACTIONS.LOGOUT}`)
        } catch (e) {}
      }
    }
  }
})
