import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'

export type PasswordRules = {
  check: boolean,
  text: TranslateResult
}[]

export default Vue.extend({
  data () {
    return {
      newPassword: '',
      confirmPassword: ''
    }
  },

  computed: {
    passwordValidation (): PasswordRules {
      return [
        { check: this.newPassword.length >= 12, text: this.$t('simplAuth.passwordValidation.minimumChars') },
        { check: /[a-z]/.test(this.newPassword), text: this.$t('simplAuth.passwordValidation.lowercase') },
        { check: /[A-Z]/.test(this.newPassword), text: this.$t('simplAuth.passwordValidation.uppercase') },
        { check: /[0-9]/.test(this.newPassword), text: this.$t('simplAuth.passwordValidation.numbers') },
        { check: /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(this.newPassword), text: this.$t('simplAuth.passwordValidation.specialChars') }
      ]
    },
    passwordIsValid (): boolean {
      let valid = true
      this.passwordValidation.forEach(rule => {
        if (!rule.check) valid = false
      })
      return valid
    },
    passwordsMatch (): boolean {
      return this.newPassword === this.confirmPassword
    }
  },

  methods: {
    validPasswordRule (): boolean | TranslateResult {
      return this.passwordIsValid || this.$t('simplAuth.passwordValidation.requirementsError')
    },
    matchingPasswordRule (): boolean | TranslateResult {
      return this.passwordsMatch || this.$t('simplAuth.error.noMatch')
    }
  }
})
