





















































































































































































































import Vue from 'vue'
import { mapState } from 'vuex'
import { appCreated } from '@simpl/core/init-app'
import { getTextForUserLanguage, nameToIdentifier } from '@simpl/core/utils'
import { NewAccountInput, User, UserExists } from '@simpl/core/types/graphql'
import { CREATE_ACCOUNT } from '../graphql'
import mixins from 'vue-typed-mixins'
import PasswordValidator from '../mixins/PasswordValidator'
import { USER_EXISTS } from '@simpl/access-control/graphql'
import PrivacyConsentCheckbox from '@simpl/core/components/PrivacyConsentCheckbox.vue'

export default mixins(
  PasswordValidator
).extend({
  name: 'Register',

  components: { PrivacyConsentCheckbox },

  props: {
    isDialog: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      // login
      registering: false,
      showPassword: false,
      companyName: '',
      namespace: '',
      input: {
        email: '',
        firstname: '',
        lastname: '',
        password: '',
        languagecode: 'de'
      },

      registrationCompleted: false,
      loading: false,

      createdUser: null as User | null,

      termsAccepted: false,

      showPasswordHints: false,

      errorMessages: '',
      rules: {
        email: (value: string) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || this.$t('simplAuth.error.invalidMail')
        },
        required: (value: string) => !!value || this.$t('core.global.required')
      },
      isFormValid: false,
      focusPassword: false,

      userExists: null! as UserExists
    }
  },

  computed: {
    ...mapState({
      loggedIn: (state: any) => !!state.auth.token && (!state.auth.user.tfa_enabled || !!state.auth.twoFactorToken)
    }),
    canSubmit (): boolean {
      return !!this.input.email &&
        !this.errorMessages.length &&
        this.isFormValid &&
        this.passwordIsValid &&
        !!this.termsAccepted
    },
    isLoading (): boolean {
      return this.registering || this.loading
    },
    validateEmails (): boolean {
      if (this.createdUser) {
        return this.createdUser!.active_domain?.properties?.validate_emails
      } else {
        return true
      }
    },
    passwordType (): string {
      return !this.input.password ? 'text' : this.showPassword ? 'text' : 'password'
    },
    headerText (): string {
      const textFromDomain = getTextForUserLanguage(this.$store.state.auth.domain, 'simplAuth.auth.createAccount')
      return (textFromDomain !== 'simplAuth.auth.createAccount') ? textFromDomain : this.$t('simplAuth.auth.createAccount') as string
    }
  },

  watch: {
    'input.password' (v) {
      this.newPassword = v
    }
  },

  methods: {
    async emailExists () {
      const exists = await this.$apollo.query({
        query: USER_EXISTS,
        variables: {
          type: 'email',
          query: this.input.email
        }
      })

      this.userExists = exists.data.userExists

      if (this.userExists.exists) {
        const statusMsg = this.userExists.status!.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
          return g1.toUpperCase() + g2.toLowerCase()
        }).replace('-', '')

        this.errorMessages = this.$t(`user.error.alreadyExists${statusMsg}`) as string
      }
    },
    populateNamespace () {
      if (!this.namespace) {
        this.namespace = nameToIdentifier(this.companyName)
      }
    },
    submit () {
      this.register()
    },
    validatePassword () {
      return this.passwordIsValid || this.$t('simplAuth.passwordValidation.requirementsError')
    },
    async onRegisterSuccessful (targetUrl?: string) {
      if (!this.isDialog) {
        await appCreated()

        setTimeout(() => {
          this.$router.push(targetUrl || '/')
        }, 200)
      } else {
        await appCreated(true)
      }
    },
    onRegisterFailed (error: any) {
      (error.graphQLErrors || []).forEach((err: Error) => {
        this.$notification.publish('bottom', {
          message: this.$t(err.message),
          borderColor: 'error'
        })
      })
    },
    async register () {
      if (!this.termsAccepted) return

      this.registering = true

      const variables: NewAccountInput = {
        email: this.input.email,
        firstname: this.input.firstname,
        lastname: this.input.lastname,
        password: this.input.password,
        languagecode: this.input.languagecode
      } as any

      if (this.companyName) {
        variables.texts = {
          create: [{
            identifier: 'display_name',
            text: this.companyName,
            languagecode: 'en'
          }]
        }
        variables.identifier = nameToIdentifier(this.companyName)
      }

      let hasErrors: Boolean = false

      const result: any = await this.$apollo.mutate({
        mutation: CREATE_ACCOUNT,
        variables: {
          data: variables
        }
      }).catch((error: Error) => {
        console.error(error.message, error.name)
        hasErrors = true
        this.onRegisterFailed(error)
      })

      if (!hasErrors) {
        this.createdUser = result.data.createAccount
        this.registrationCompleted = true
      }
      this.registering = false
    },
    onFocusPassword () {
      this.showPasswordHints = true
      this.focusPassword = true
    }
  }
})
